//(function($) {

"use strict";

var JobOrderEvent = Backbone.Model.extend({
    initialize: function(attributes, options) {
        this.urlRoot = Core.contextRoot + '/order/' + options.orderId + '/event'
    }
});

var JobOrderEvents = Backbone.Collection.extend({
    model: JobOrderEvent
});

var JobOrder = Core.ShareableModel.extend({
	urlRoot: Core.contextRoot + '/order',
    editGroupPermission: 'admin_orders',

    defaults: function() {
        return {
            documents: [],
            labels: [],
            jobSpecifications: [],
            autoCreateJobsDays: 7
        };
    },

    setFromTemplate: function (template, previousTemplate, options) {
        var attributes = $.extend(true, {}, template.attributes);
        attributes.fromTemplateId = template.attributes.id;
        delete attributes.title;
        delete attributes.id;
        delete attributes.uuid;
        delete attributes.version;
        delete attributes.lastModified;
        delete attributes.creatorId;
        delete attributes.creationTimeStamp;
        delete attributes.discrepancy;
        delete attributes.events;
        delete attributes.template;
        delete attributes.state;
        if (attributes.documents)
            _(attributes.documents).each(function (document) { delete document.id; delete document.uuid; });

        attributes.jobSpecifications = _(attributes.jobSpecifications).filter(function (spec) { return spec.addedDefault != false; });

        // Retain values from previous template
        var current = this.attributes;
        var previous = previousTemplate ? previousTemplate.attributes : {};

        if (current.startTime != previous.startTime) delete attributes.startTime;
        if (current.endTime != previous.endTime) delete attributes.endTime;
        if (current.duration != previous.duration) delete attributes.duration;
        if (current.customerId != previous.customerId) delete attributes.customerId;
        if (current.customerProjectId != previous.customerProjectId) delete attributes.customerProjectId;
        if (current.assetId != previous.assetId) delete attributes.assetId;
        if (current.activityId != previous.activityId) delete attributes.activityId;

        if (!this.savedCustomFields) this.savedCustomFields = [];
        _(this.get('customFields')).each(function (field) {
            var existingField = _(this.savedCustomFields).findWhere({name: field.name});
            if (existingField)
                existingField.value = field.value;
            else
                this.savedCustomFields.push(_(field).clone());
        }, this);
        if (attributes.customFields && current.customFields) {
            _(current.customFields).each(function (prefield) {
                var postfield = _(attributes.customFields).findWhere({name: prefield.name});
                if (!postfield) postfield = _(attributes.customFields).findWhere({label: prefield.label});
                if (postfield) postfield.value = prefield.value;
            }, this);
        }
        _(this.savedCustomFields).each(function (field) {
            if (!field.value) return;
            var postfield = _(attributes.customFields).findWhere({name: field.name});
            if (!postfield) postfield = _(attributes.customFields).findWhere({label: field.label});
            if (postfield && !postfield.value) postfield.value = field.value;
        }, this);

        // Don't change group if we don't have permission in that group
        if (!Core.client.hasGroupPermission(attributes.groupId, 'create_orders')) delete attributes.groupId;
        // Retain current group if template is from parent group
        if (template.isValidForGroup(current.groupId)) delete attributes.groupId;

        this.set(attributes, options);
    },

    createJob: function() {
      return $.ajax({
          url: Core.contextRoot + '/job/order/' + this.id,
          type: 'POST',
          dataType: 'json'
      });
    },

    addJobSpecification: function(jobSpecification, options) {
        if (!jobSpecification) {
            var group = Core.client.groups.get(this.get('groupId'));
            if (!group) group = Core.client.primaryGroup();
            var jobTemplateId = group ? group.get('defaultJobTemplateId') : null;
            jobSpecification = {jobContent: {}, jobTemplateId: jobTemplateId};
        }
        var jobSpecifications = _(this.get('jobSpecifications')).clone();
        jobSpecifications.push(jobSpecification);
        this.set({jobSpecifications: jobSpecifications}, options);
    },

    attachDocument: function(documentId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'PUT'
        });
    },

    detachDocument: function(documentId) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'DELETE'
        }).success(function() {
            self.set({documents: _(self.get('documents')).filter(function(d) { return d.id != documentId && d.uuid != documentId }, { silent: true })});
            self.trigger('detachDocument', self);
        });
    },

	isDispatchable: function() {
        if (this.get('deleted')) return false;
        return Core.client.hasPermission('create_jobs') && (!this.get('groupId') || Core.client.hasGroupPermission(this.get('groupId'), 'create_jobs'));
    },
	canCreateJob: function() { return this.isDispatchable() && (this.get('state') == 'PENDING' || this.get('state') == 'CONFIRMED' || this.get('state') == 'PUBLISHED' || this.get('state') == 'SCHEDULED' || this.get('state') == 'ACTIVE'); },
    canCancel: function() { return this.isEditable() && this.get('state')  != 'DRAFT' && this.get('state')  != 'CANCELED' && this.get('state')  != 'DELIVERED'; },
    canEnd: function() { return this.isEditable() && this.isRecurring() && (this.get('state')  == 'ACTIVE' || this.get('state') == 'INACTIVE'); },
    canActivate: function() { return this.isEditable() && this.isRecurring() && this.get('state')  == 'INACTIVE'; },
    canDeactivate: function() { return this.isEditable() && this.isRecurring() && this.get('state')  != 'INACTIVE' && this.get('state') != 'DRAFT' && this.get('state') != 'PENDING' && this.get('state')  != 'CANCELED' && this.get('state')  != 'DELIVERED'; },
    isRecurring: function() {
        if ('recurring' in this.attributes) return this.get('recurring');

        var jobSpecifications = this.get('jobSpecifications');
        for (var i = 0; i < jobSpecifications.length; i++) {
            var jobSpecification = jobSpecifications[i];
            if (jobSpecification.recurringIntervals && jobSpecification.recurringIntervals.length > 0) return true;
        }
        return false;
    },
	
	titleExtended: function() {
		var customerName = this.customerName() || this.get('pendingCustomerName');
		var title = this.get('title');
        if (title) return title;
		if (!title)
			title = this.projectName();
		if (!title)
			title = ''+(this.get('referenceNo') || this.get('orderNo') || '');
		if (customerName && title)
			title = customerName + ": " + title;
		else if (customerName)
			title = customerName;
        if (Core.client.groupHasConfiguration(Core.client.me.get('primaryGroupId'), 'always_show_customer'))
            title = title.replace(this.customerName()+": ","");
        return title;
	},
	
	customer: function() { if (this.get('customerId') && Core.client.customers) return Core.client.customers.get(this.get('customerId')); },
	customerName: function() {
        if ('customerName' in this.attributes) return this.get('customerName');
		var customer = this.customer();
		if (customer) return customer.get('name');
	},
    referenceNo: function() {
      return this.get('referenceNo');
    },
	project: function() { 
		var customer = this.customer();
		if (customer)
			return _(customer.get('projects')).find(function (project) { return project.id == this.get('customerProjectId'); }, this);
	},
	projectName: function() {
        if ('customerProjectName' in this.attributes) return this.get('customerProjectName');
		var project = this.project();
		if (project) return project.name;
	},

    fetchAllEvents: function(callback) {
        var orderEvents = new JobOrderEvents(this.get('events'));
        var jobEvents = new JobOrderEvents();
        jobEvents.url = this.url() + '/jobevents';
        return jobEvents.fetch().success(function() {
            // Filter out job events already covered by order events
            var eventByJobEventId = {};
            orderEvents.each(function(event) {
                if (event.get('jobEvent')) eventByJobEventId[event.get('jobEvent').id] = event;
            });
            jobEvents.models = jobEvents.filter(function (event) {
                return !(event.get('jobEvent') && event.get('jobEvent').id in eventByJobEventId);
            });

            var events = new JobOrderEvents();
            events.add(orderEvents.models);
            events.add(jobEvents.models);
            events.models = events.sortBy('eventTimeStamp');
            if (callback) callback(events.toJSON());
        });
    },

    getResource: function(userId) {
        return _(this.get('resources')).find(function (resource) { return resource.userId == userId; });
    },

    myResource: function() {
        return this.getResource(Core.client.me.id);
    },

    isLate: function() {
        return this.get('state') != 'DELIVERED' && this.get('state') != 'CANCELED' && this.get('state') != 'INACTIVE' && this.get('state') != 'DRAFT'
            && ( (this.isRecurring() && this.get('endDate') && this.get('endDate') < new Date().setHours(0,0,0,0))
            || (!this.isRecurring() && this.get('startDate') && this.get('startDate') && this.get('startDate') < new Date().setHours(0,0,0,0)));
    },

    overrideState: function(state) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/state/' + state,
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    shareByEmail: function(emailAddress, message, shareInternalInfo, pdf, include) {
        var data = { to: emailAddress, message: message };
        if (shareInternalInfo) data.internal = true;
        if (pdf) data.pdf = true;
        if (typeof include == 'object')
            data.include = include.join(',');
        else if (include)
            data.include = include;
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/share/email',
            type: 'POST',
            data: data
        }).success(function (data) {
            self.attributes.events.push({
                type: 'SHARE',
                comment: emailAddress,
                userId: Core.client.me.get('id'),
                eventTimeStamp: new Date().getTime(),
                internal: true
            });
        });

    },

    sendConfirmation: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/confirm',
            type: 'POST'
        }).success(function (data) {
            self.attributes.events.push({
                type: 'SEND_CONFIRMATION',
                comment: self.get('contact').emailAddress,
                userId: Core.client.me.get('id'),
                eventTimeStamp: new Date().getTime()
            });
            if (self.get('state') == 'PENDING' || self.get('state') == 'DRAFT')
                self.set('state','CONFIRMED');
        });
    },

    follow: function(follower) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(follower),
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    unfollow: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'DELETE'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    geocode: function() {
        var destinations = [];
        _(this.get('jobSpecifications')).each(function (spec) {
            if (spec.jobContent && spec.jobContent.destinations)
                destinations = destinations.concat(spec.jobContent.destinations);
        }, this);
        // Geocode the FIRST NON-GEOCODED address (and return the xhr object)
        for (var i = 0; i < destinations.length; i++) {
            var destination = destinations[i];
            if (!destination.location) destination.location = {};
            if (!destination.pendingGeocode && (destination.geocodeResult == 'fail' || (destination.location.latitude && destination.location.longitude))) continue;
            if (!destination.location.placeName) continue;
            this.trigger('geocodestart');
            console.log('geocode', destination.location.placeName);
            var self = this;
            return $.getJSON(Core.contextRoot + '/map/search?' + $.param({q: destination.location.placeName}), function (response) {
                if (response.places && response.places.length >= 1) {
                    var place = response.places[0];
                    console.log(destination.location.placeName + ' => \n' + place.name + ' ' + place.latitude + ' ' + place.longitude + ' - ' + place.type + ' ' + place.source);
                    destination.location.latitude = place.latitude;
                    destination.location.longitude = place.longitude;
                    destination.geocodedPlace = place;
                    if (place.type == 'STREET_ADDRESS' || place.type == 'PLACE')
                        destination.geocodeResult = 'ok';
                    else
                        destination.geocodeResult = 'warn';
                } else {
                    console.log('geocoding failed', response, destination.location.placeName);
                    delete destination.geocodedPlace;
                    destination.geocodeResult = 'fail';
                }
                self.geocodeResult = destination.geocodeResult;
                delete destination.pendingGeocode;
                delete destination.geocodedReverse;
                self.trigger('geocodedone');
            });
        }

    },

    myFollower: function() { return _(this.get('followers')).findWhere({userId: Core.client.me.id}); },
    myFollowerOptions: function() {
        var myOptions = [];
        if (!Core.client.groups || !this.get('groupId')) return null;
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return null;
        var myFollower = this.myFollower();
        if (myFollower) {
            if (myFollower.options != null)
                return myFollower.options;
            else
                return ['states', 'discrepancies']; // Follower.DEFAULT_OPTIONS
        }
        var isResource = false;
        var isDispatcher = false;
        if (this.get('resources')) {
            isResource = this.myResource();
            isDispatcher = _(this.get('resources')).findWhere({dispatcherId: Core.client.me.id});
        } else {
            _(this.get('jobSpecifications')).each(function (spec) {
                if (spec.jobContent && spec.jobContent.resources) {
                    _(spec.jobContent.resources).each(function (resource) {
                        if (resource.userId == Core.client.me.id) isResource = true;
                        if (resource.dispatcherId == Core.client.me.id) isDispatcher = true;
                    });
                }
            });
        }
        var groupOptions = group.get('followerOptions');
        if (groupOptions['orderCreator'] && Core.client.me.id == this.get('creatorId')) {
            var options = groupOptions['orderCreator'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['orderResource'] && isResource) {
            var options = groupOptions['orderResource'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['orderDispatcher'] && isDispatcher) {
            var options = groupOptions['orderDispatcher'];
            if (myOptions.length < options.length) myOptions = options;
        }
        return _(myOptions).uniq();
    },

    sortTime: function() {
        if (this.get('startDate') && this.get('startTime')) return this.get('startTime');
        if (this.get('startDate')) return this.get('startDate');
        if (this.get('endDate')) return this.get('endDate');
        if (this.get('creationTimeStamp')) return this.get('creationTimeStamp');
        return Number.MAX_VALUE;
    },

    sortState: function() {
        switch (this.get('state')) {
            case 'INITIAL': return -1;
            case 'DRAFT': return 0;
            case 'INACTIVE': return 1;
            case 'PENDING': return 2;
            case 'CONFIRMED': return 3;
            case 'PUBLISHED': return 4;
            case 'ACTIVE': return 5;
            case 'DELIVERED': return 6;
            case 'CANCELED': return 7;
            default: return Number.MAX_VALUE;
        }
    },

});

var JobOrders = Backbone.Collection.extend({
	model: JobOrder,

	url: function() {
		// Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.states) {
            if (typeof this.filterParams.states == 'object')
                params.states = this.filterParams.states.join(',');
            else
                param.states = this.filterParams.states;
        } else {
            if (this.filterParams.includeDelivered) params.delivered = '';
            if (this.filterParams.includeCanceled) params.canceled = '';
            if (this.filterParams.includeDrafts) params.drafts = '';
            if (this.filterParams.includeInactive) params.inactive = '';
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;

        if (this.searchQuery) {
            url = Core.contextRoot + '/order/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/order/list' + (this.filterParams.path ? '/' + this.filterParams.path : '');
            if (this.filterParams.path && this.filterParams.path == 'templates') {
                url = Core.contextRoot + '/order/templates';
                if (this.filterParams.includeDrafts) params.drafts = true;
                if (this.filterParams.onlyEditable) params.only_editable = true;
            } else {
                if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
                    var fromDate = new Date();
                    fromDate.setHours(0,0,0,0);
                    this.filterParams.from = fromDate.getTime();
                }
                params.from = this.filterParams.from;
                if (this.filterParams.to)
                    params.to = this.filterParams.to;
                if ('includeCurrent' in this.filterParams) {
                    if (this.filterParams.includeCurrent)
                        params.current = '';
                } else if (!this.filterParams.to)
                    params.current = '';
            }
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;
        if (this.filterParams.offset) params.offset = this.filterParams.offset;
		var paramsStr = $.param(params);
		if (paramsStr.length > 0)
			url += '?' + paramsStr;
		return url;
	},
	
	initialize: function(models, options) {
        this.filterParams = {
            path: 'all',
            states: ['DRAFT','INACTIVE','CONFIRMED','PENDING','PUBLISHED','SCHEDULED','ACTIVE','DELIVERED','CANCELED'],
            from: null,
            to: null,
            userId: null
        };
		if (options && options.filterParams)
			_(this.filterParams).extend(options.filterParams);
		else if ('orders.filterParams' in sessionStorage)
			this.filterParams = JSON.parse(sessionStorage['orders.filterParams']);
	},

	saveFilter: function() {
		// Save filter parameters in sessionStorage
		sessionStorage['orders.filterParams'] = JSON.stringify(this.filterParams);
		sessionStorage['orders.list'] = this.filterParams.path;
	},

    forEachRelevantTemplate: function(callback, context) {
        var limitToPrimaryGroup = Core.client.me.get('shareAllWithPrimaryGroup') && this.filter(function (template) {
                return template.isValidForGroup(Core.client.me.get('primaryGroupId')) && Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_orders');
            }).length > 0;
        return this.each(function (template) {
            var defaultTemplate = Core.client.primaryGroup() && Core.client.primaryGroup().getOrGetInParentGroup('defaultOrderTemplateId') == template.id;
            if (template.get('groupId') && !Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_orders')) return;
            if (limitToPrimaryGroup && !template.isValidForGroup(Core.client.me.get('primaryGroupId'))) return;
            callback.apply(context, [template, defaultTemplate]);
        });
    }
});

JobOrders.filterCollection = function(filter, collection) {
    var filter = _(filter).clone();
    if (!filter.list) filter.list = 'all';
    var userId;
    var assetTypeId;
    var label;
    if (filter.list == 'mine') {
        userId = Core.client.me.id;
        delete filter.where;
    } else if (filter.list.indexOf('user-') == 0) {
        userId = parseInt(filter.list.replace('user-', ''));
        delete filter.where;
    } else if (filter.list.indexOf('label-') == 0) {
        label = filter.list.replace('label-', '');
        delete filter.where;
    } else if (filter.list.indexOf('group-') == 0) {
        filter.where = { groupId: parseInt(filter.list.replace('group-','')) };
    } else if (filter.list.indexOf('customer-') == 0) {
        filter.where = {customerId: parseInt(filter.list.replace('customer-', ''))};
    } else if (filter.list.indexOf('asset-') == 0) {
        filter.where = {assetId: parseInt(filter.list.replace('asset-', ''))};
    } else if (filter.list.indexOf('assettype-') == 0) {
        assetTypeId = parseInt(filter.list.replace('assettype-', ''));
        delete filter.where;
    } else if (filter.list == 'all' || filter.list == 'unassigned' || filter.list == 'today') {
        delete filter.where;
    } else {
        console.error('Unknown filter selection ' + filter.list);
    }
    var filteredCollection = new JobOrders(filter.where ? collection.where(filter.where) : collection.models);
    if (userId) {
        filteredCollection.reset(filteredCollection.filter(function (order) {
            return order.getResource(userId) || order.get('creatorId') == userId || order.get('ordererId') == userId;
        }, this), { silent: true });
    } else if (assetTypeId) {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            var asset = Core.client.assets.get(job.get('assetId'));
            return asset && asset.get('assetTypeId') == assetTypeId;
        }, this), {silent: true})
    }
    if (label) {
        filteredCollection.reset(filteredCollection.filter(function (order) {
            return order.get('labels') && order.get('labels').indexOf(label) >= 0;
        }, this), { silent: true })
    }
    // filter out deletes and state changes from updates
    filteredCollection.reset(filteredCollection.filter(function (order) {
        if (collection.filterParams && collection.filterParams.states && collection.filterParams.states.indexOf(order.get('state')) < 0) return false;
        return !order.get('deleted');
    }), { silent: true });
    return filteredCollection;
};

//})(jQuery);
