"use strict";

Core.Device = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/device',

    findAlarm: function(alarmType) {
        var alarms = this.get('alarms');
        if (!alarms || alarms.length == 0) return;
        for (var j = 0; j < alarms.length; j++) {
            var alarm = alarms[j];
            if (alarm.type == alarmType) return alarm;
        }
    }

});

Core.Vehicle = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/vehicle',
    editGroupPermission: 'admin_vehicles',

    removeOccupants: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/occupant',
            type: 'DELETE'
        }).success(function (data) {
            self.set(data);
        });
    },

});

Core.Vehicles = Backbone.Collection.extend({
    model: Core.Vehicle,
    url: function() {
        var path = '/list';
        var params = {};
        if (this.searchQuery) {
            path = '/search';
            params.q = this.searchQuery;
        } else if (this.filterParams) {
            params = this.filterParams.params || {};
            if (typeof this.filterParams.path !== 'undefined') path = '/' + this.filterParams.path;
            if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
        }
        if (this.searchQuery) params.q = this.searchQuery;
        return Core.contextRoot + '/vehicle' + path + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    },

    initialize: function(models, options) {
        if (options) {
            this.filterParams = options.filterParams;
        }
    },
});
