"use strict";

var JobEvent = Backbone.Model.extend({

    initialize: function(attributes, options) {
        this.urlRoot = Core.contextRoot + '/job/' + options.jobId + '/event'
    }
});

var Job = Core.ShareableModel.extend({
	urlRoot: Core.contextRoot + '/job',
    editGroupPermission: 'admin_jobs',

    initialize: function(attributes, options) {
        if (options && options.urlRoot) this.urlRoot = options.urlRoot;
    },

    defaults: function() {
        return {
            uuid: Core.randomUUID(),
            state: 'PUBLISHED',
            documents: [],
            labels: [],
            stateTracked: true,
            toBeReported: true,
            toBeInvoiced: true,
        };
    },

    emptyTemplate: function() {
        return {
            description: '',
            workItems: [],
            destinations: [],
            items: [],
            forms: [],
            documents: [],
            resources: [],
            excludedFields: [],
            customFields: [],
            internalFields: [],
            requiredFields: []
        }
    },

    setFromTemplate: function (template, previousTemplate) {
        //console.log('Job.setFromTemplate', template ? template.get('title') : 'NONE', template.attributes);
        // similar to domain: Job.copyFromTemplate() and client: JobCache.createJobFromTemplate()
        var attributes = $.extend(true, {}, template.attributes);
        attributes.fromTemplateId = template.attributes.id;
        delete attributes.title;
        delete attributes.id;
        delete attributes.uuid;
        delete attributes.version;
        delete attributes.lastModified;
        delete attributes.creatorId;
        delete attributes.creationTimeStamp;
        //delete attributes.resources;
        delete attributes.state;
        delete attributes.finalState;
        delete attributes.jobNo;
        delete attributes.template;
        delete attributes.discrepancy;
        //delete attributes.unconfirmedDiscrepancy;
        delete attributes.startedTimeStamp;
        delete attributes.endedTimeStamp;
        delete attributes.events;
        delete attributes.invoiceId;
        delete attributes.invoicedTimeStamp;
        delete attributes.invoicedById;
        delete attributes.totalInvoicedPrice;
        delete attributes.pendingInvoicingPrice;
        delete attributes.pendingInvoicingPriceComplete;

        if (attributes.workItems)
            _(attributes.workItems).each(function (workItem) {
                delete workItem.id;
                delete workItem.done;
                delete workItem.doneByUserId;
                delete workItem.doneTimeStamp;
            });
        if (attributes.destinations) {
            _(attributes.destinations).each(function (destination) {
                if (destination.workItems)
                    _(destination.workItems).each(function (workItem) {
                        delete workItem.id;
                        delete workItem.done;
                        delete workItem.doneByUserId;
                        delete workItem.doneTimeStamp;
                    });
                delete destination.id;
            });
        }
        if (attributes.resources)
            _(attributes.resources).each(function (resource) {
                delete resource.id;
                if (resource.state != 'ASSIGNED' && resource.state != 'UNASSIGNED') resource.state = 'SCHEDULED';
            });
        if (attributes.items)
            _(attributes.items).each(function (item) {
                delete item.id;
                delete item.uuid;
                delete item.invoiceId;
                delete item.invoicedTimeStamp;
                delete item.deliveryDate;
                delete item.deliveredById;
                delete item.delivered;
            });
        if (attributes.documents)
            _(attributes.documents).each(function (document) { delete document.id; delete document.uuid; });
        if (attributes.forms)
            _(attributes.forms).each(function (form) { delete form.formId; delete form.formUuid; delete form.formState; });

        // Retain values from previous template
        var current = this.attributes;
        var previous = previousTemplate ? previousTemplate.attributes : this.emptyTemplate();

        if (current.startTime != previous.startTime) delete attributes.startTime;
        if (current.endTime != previous.endTime) delete attributes.endTime;
        if (current.duration != previous.duration) delete attributes.duration;
        if (current.customerId != previous.customerId) delete attributes.customerId;
        if (current.customerProjectId != previous.customerProjectId) delete attributes.customerProjectId;
        if (current.assetId != previous.assetId) delete attributes.assetId;
        if (current.activityId != previous.activityId) delete attributes.activityId;

        // Don't change group if we don't have permission in that group
        if (!Core.client.hasGroupPermission(attributes.groupId, 'create_jobs')) delete attributes.groupId;
        // Retain current group if template is from parent group
        if (template.isValidForGroup(current.groupId)) delete attributes.groupId;

        var contact = current.contact;
        var prevContact = previous.contact;
        if (contact && prevContact) {
            if (contact.name != prevContact.name || contact.emailAddress != prevContact.emailAddress || contact.phoneNo != prevContact.phoneNo)
                delete attributes.contact;
        } else if (contact != prevContact)
            delete attributes.contact;

        if (previousTemplate && current.description != previous.description) delete attributes.description;

        if (!this.savedCustomFields) this.savedCustomFields = [];
        _(this.get('customFields')).each(function (field) {
            var existingField = _(this.savedCustomFields).findWhere({name: field.name});
            if (existingField)
                existingField.value = field.value;
            else
                this.savedCustomFields.push(_(field).clone());
        }, this);
        if (attributes.customFields && current.customFields) {
            _(current.customFields).each(function (prefield) {
                var postfield = _(attributes.customFields).findWhere({name: prefield.name});
                if (!postfield) postfield = _(attributes.customFields).findWhere({label: prefield.label});
                if (postfield) postfield.value = prefield.value;
            }, this);
        }
        _(this.savedCustomFields).each(function (field) {
            if (!field.value) return;
            var postfield = _(attributes.customFields).findWhere({name: field.name});
            if (!postfield) postfield = _(attributes.customFields).findWhere({label: field.label});
            if (postfield && !postfield.value) postfield.value = field.value;
        }, this);

        if (current.workItems && current.workItems.length > 0 && (!attributes.workItems || attributes.workItems.length == 0) && (!previous.workItems || previous.workItems.length == 0))
            attributes.workItems = current.workItems;

        if (current.destinations && current.destinations.length > 0 && previous.destinations && previous.destinations.length > 0) {
            var keepDestinations = false;
            for (var i = 0; i < current.destinations.length; i++) {
                if (i >= previous.destinations.length) {
                    keepDestinations = true;
                    break;
                }
                if (current.destinations[i].location && previous.destinations[i].location) {
                    if (current.destinations[i].location.placeName != previous.destinations[i].location.placeName) {
                        keepDestinations = true;
                        break;
                    }
                }
            }

            if (keepDestinations) {
                while (attributes.destinations && attributes.destinations.length > current.destinations.length) {
                    current.destinations.push({});
                }
                if (attributes.destinations && attributes.destinations.length < current.destinations.length) {
                    current.destinations.splice(attributes.destinations.length, current.destinations.length);
                }
                attributes.destinations = current.destinations;
            }
        }
        if (current.items && current.items.length > 0 && (!attributes.items || attributes.items.length == 0) && (!previous.items || previous.items.length == 0))
            attributes.items = current.items;
        if (current.documents && current.documents.length > 0 && (!attributes.documents || attributes.documents.length == 0) && (!previous.documents || previous.documents.length == 0))
            attributes.documents = current.documents;

        this.set(attributes);
    },

    // getters

	isDispatchable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        if (this.get('routeId')) return false;
        if (this.get('state') == 'CLOSED') return false;
		if (this.get('groupId'))
			return Core.client.hasGroupPermission(this.get('groupId'), 'dispatch');
		else
			return this.isEditable();
	},
	
	isPublished: function() {
		var state = this.get('state');
		return (state && state != 'ORDER' && state != 'DRAFT' && state != 'CLOSED');
	},
	
	isActive: function() {
		var state = this.get('state');
		return (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED');
	},
	
	routeId: function() {
		return this.get('destinations') && this.get('destinations')[0] && this.get('destinations')[0].routeId;
	},

	isLate: function() {
		var state = this.get('state');
        var startTime = this.get('startTime');
        var endTime = this.get('endTime');
        var allDay = this.get('allDay');
        var stateTracked = this.get('stateTracked');
        var duration = this.get('duration');
		if (state == 'INITIAL' || state == 'DRAFT' || state == 'FINISHED' || state == 'CANCELED' || state == 'CLOSED')
			return false;
		if (endTime && new Date().getTime() > endTime + (allDay ? 24*3600000 : 0))
			return true;
        if (!allDay && !endTime && startTime && duration && new Date().getTime() > startTime + duration*60000)
            return true;
        if (stateTracked && (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED' || state == 'DAY_FINISHED'))
            return false;
        if (stateTracked && startTime && new Date().getTime() > startTime + (allDay ? 24*3600000 : 0))
            return true;
		return false;
	},
	
	isResourceScheduled: function(resource) {
		var state = resource.state;
		return resource.userId && state != 'UNASSIGNED' && state != 'ASSIGNED' && state != 'DECLINED';
	},
	
	isResourceLate: function(resource) {
        if (!resource) return undefined;
		var state = resource.state;
        var startTime = this.get('startTime');
        var endTime = this.get('endTime');
        var allDay = this.get('allDay');
        var stateTracked = this.get('stateTracked');
        var duration = this.get('duration');
		if (state == 'FINISHED' || state == 'CANCELED' || state == 'DECLINED')
			return false;
        if (endTime != null && new Date().getTime() > endTime + (allDay ? 24*3600000 : 0))
            return true;
        if (!allDay && !endTime && startTime && duration && new Date().getTime() > startTime + duration*60000)
            return true;
        if (stateTracked && (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED' || state == 'DAY_FINISHED'))
            return false;
        if (stateTracked && startTime && new Date().getTime() > startTime + (allDay ? 24*3600000 : 0))
            return true;
		return false;
	},
	
	getResourceId: function(resourceId) {
		return _(this.get('resources')).find(function (resource) { return resource.id == resourceId; });
	},
	
	getResource: function(userId) {
		return _(this.get('resources')).find(function (resource) { return resource.userId == userId; });
	},

    getResourceByVehicle: function(vehicleId) {
        return _(this.get('resources')).find(function (resource) { return resource.vehicleId == vehicleId; });
    },
	
	myResource: function() {
		return this.getResource(Core.client.me.id);
	},

    myScheduledResource: function() {
        var resource = this.myResource();
        return resource && resource.state != 'ASSIGNED' && resource.state != 'DECLINED';
    },
	
	getNotes: function() {
		if (!this.attributes.events)
			return [];
		var notes = [];
		for (var i = 0; i < this.attributes.events.length; i++) {
			var event = this.attributes.events[i];
			if (event.type == 'NOTE')
				notes.push(event);
		}
		return notes;
	},
	
	isWorkItemDone: function(id) {
		for (var i = 0; i < this.attributes.workItems.length; i++) {
			var item = this.attributes.workItems[i];
			if (item.id == id)
				return item.done;
		}
		for (var d = 0; d < this.attributes.destinations.length; d++) {
			var destination = this.attributes.destinations[d];
			for (var i = 0; i < destination.workItems.length; i++) {
				var item = destination.workItems[i];
				if (item.id == id)
					return item.done;
			}
			
		}
	},
	
	canScheduleMe: function() {
		if (Core.client.me.id && !this.myResource() && this.isPublished() && (this.get('selfScheduled') || this.isDispatchable() || this.get('creatorId') == Core.client.me.id)) {
			if (this.get('groupId') && !Core.client.hasGroupPermission(this.get('groupId'), 'do_work'))
				return false;
			if (this.getFreeResource())
				return true;
		}
		return false;
	},
	
	canScheduleOthers: function() {
        return this.isDispatchable() && this.isPublished() && this.getFreeResource();
	},

    canAddResource: function() {
        return !this.get('routeId') && this.get('state') != 'CLOSED' && (this.isDispatchable() || (this.myScheduledResource() && this.get('selfScheduled') && Core.client.hasGroupPermission(this.get('groupId'), 'view_other_users')));
    },
	
	getFreeResource: function() {
        if (this.get('routeId')) return null;
		var freeResource = _(this.get('resources')).find(function (resource) { return !resource.userId || resource.state == 'DECLINED'; });
        if (!freeResource && (!this.get('resources') || this.get('resources').length == 0)) return {};
        return freeResource;
	},

    getDestination: function(destinationId) {
        return _(this.get('destinations')).find(function (destination) { return destination.id == destinationId; });
    },

    // actions
	
	accept: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/accept',
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	decline: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/decline',
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	close: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/close',
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},

    attest: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/attest',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },
	
	publish: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/publish',
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	unpublish: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/unpublish',
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	unschedule: function(userId, sendMessage, resourceId) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage)
			params.sendMessage = true;
        if (resourceId)
            params.resourceId = resourceId;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/unschedule?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data, {silent: true});
		});
	},
	
	schedule: function(userId, sendMessage) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage) params.sendMessage = true;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/schedule?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data, {silent: true});
		});
	},
	
	assign: function(userId, sendMessage) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage) params.sendMessage = true;
		return $.ajax({
			url: this.urlRoot + '/' + this.get('uuid') + '/assign?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data, {silent: true});
		});
	},

    scheduleVehicle: function(vehicleId, resource) {
        var self = this;
        var params = { vehicleId: vehicleId };
        if (resource && resource.userId) params.userId = resource.userId;
        if (resource && resource.id) params.resourceId = resource.id;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/schedule?' + $.param(params),
            type: 'PUT'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    unscheduleVehicle: function(vehicleId, resource) {
        var self = this;
        var params = { vehicleId: vehicleId };
        if (resource && resource.userId) params.userId = resource.userId;
        if (resource && resource.id) params.resourceId = resource.id;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/unschedule?' + $.param(params) ,
            type: 'PUT'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    addResource: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/resource',
            type: 'POST'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    deleteResource: function(resourceId) {
        if (typeof resourceId == 'object') resourceId = resourceId.id;
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/resource/' + resourceId,
            type: 'DELETE'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    overrideState: function(state, includeResources) {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/state/' + state + (includeResources ? '?resources' : ''),
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	reportUserState: function(userId, state) {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/user/' + userId + '/state/' + state,
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	reportWorkItemDone: function(workItem, done) {
		if (typeof done == 'undefined')
			done = true;
		workItem.done = done;
		workItem.doneByUserId = Core.client.me.id;
		workItem.doneTimeStamp = new Date().getTime();
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/workitem/' + workItem.id,
			type: 'PUT',
			data: { done: done }
		});
	},

    follow: function(follower) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(follower),
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    unfollow: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'DELETE'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    geocode: function() {
        if (this.get('destinations')) {
            // Geocode the FIRST NON-GEOCODED address (and return the xhr object)
            for (var i = 0; i < this.get('destinations').length; i++) {
                var destination = this.get('destinations')[i];
                if (!destination.location) destination.location = {};
                if (!destination.pendingGeocode && (destination.geocodeResult == 'fail' || (destination.location.latitude && destination.location.longitude))) continue;
                if (!destination.location.placeName) continue;
                if (destination.location.placeName.indexOf('<') == 0) continue; // special case for having a label like "<<< pick a place"
                this.trigger('geocodestart');
                console.log('geocode', destination.location.placeName);
                var self = this;
                return $.getJSON(Core.contextRoot + '/map/search?' + $.param({q: destination.location.placeName}), function (response) {
                    if (response.places && response.places.length >= 1) {
                        var place = response.places[0];
                        console.log(destination.location.placeName + ' => \n' + place.name + ' ' + place.latitude + ' ' + place.longitude + ' - ' + place.type + ' ' + place.source);
                        destination.location.latitude = place.latitude;
                        destination.location.longitude = place.longitude;
                        destination.geocodedPlace = place;
                        if (place.type == 'STREET_ADDRESS' || place.type == 'PLACE')
                            destination.geocodeResult = 'ok';
                        else
                            destination.geocodeResult = 'warn';
                    } else {
                        console.log('geocoding failed', response, destination.location.placeName);
                        delete destination.geocodedPlace;
                        destination.geocodeResult = 'fail';
                    }
                    self.geocodeResult = destination.geocodeResult;
                    delete destination.pendingGeocode;
                    delete destination.geocodedReverse;
                    self.trigger('geocodedone');
                });
            }
        }
    },

    reverseGeocodeDestination: function(destination) {
        var params = $.param({lat: destination.location.latitude, lon: destination.location.longitude});
        return $.getJSON(Core.contextRoot + '/map/search?' + params, function (result) {
            if (result.places) {
                var address = result.places[0].name;
                address = address.replace(', ' + result.places[0].country, '');
                destination.location.placeName = address;
                destination.geocodedPlace = result.places[0];
            }
            destination.geocodedReverse = true;
            destination.pendingGeocode = false;
        });
    },

    route: function(routeTemplate) {
        if (this.get('destinations')) {
            // Route the FIRST NON-ROUTED address (and return the xhr object)
            for (var i = 1; i < this.get('destinations').length; i++) {
                var destination = this.get('destinations')[i];
                if (destination.routeData || destination.routed) continue;
                if (!destination.location || !destination.location.latitude || !destination.location.longitude) {
                    i++;
                    continue;
                }
                return this.routeDestination(destination, routeTemplate);
            }
        }
    },

    routeDestination: function(toDestination, routeTemplate) {
        if (typeof toDestination == 'number')
            toDestination = this.get('destinations')[toDestination];
        if (!toDestination) {
            console.error('Cannot route destination - toDestination is null!');
            return null;
        }
        var fromDestination = this.get('destinations')[this.get('destinations').indexOf(toDestination)-1];
        if (!fromDestination) {
            console.error('Cannot route destination - fromDestination is null!');
            return null;
        }
        var params = {};
        if (routeTemplate) {
            if (routeTemplate.get('modeOfTransport'))
                params.transport = routeTemplate.get('modeOfTransport');
            if (routeTemplate.get('type'))
                params.type = routeTemplate.get('type');
            if (routeTemplate.get('timeCorrection'))
                params.timecorrection = routeTemplate.get('timeCorrection');
        }
        return $.ajax({
            url: Core.contextRoot + '/routing/leg/route?' + $.param(params),
            type: 'POST',
            data: JSON.stringify([ fromDestination, toDestination ]),
            contentType: 'application/json',
            success: function(leg) {
                _(toDestination).extend(leg[1]);
                toDestination.routed = true;
            }
        });
    },

    removeDestination: function(destination) {
        var destinations = this.get('destinations');
        var index = destinations.indexOf(destination);
        if (index >= 0) {
            destinations.splice(index, 1);
            // Remove route data from next
            if (!this.get('routeId') && index < destinations.length) {
                var nextDestination = destinations[index];
                delete nextDestination.routeData;
                delete nextDestination.routeTime;
                delete nextDestination.routeDistance;
            }
        } else
            console.error('destination not found', destination, destinations);
    },

    shareByEmail: function(emailAddress, message, shareInternalInfo, pdf, include) {
        var data = { to: emailAddress, message: message };
        if (shareInternalInfo) data.internal = true;
        if (pdf) data.pdf = true;
        if (typeof include == 'object')
            data.include = include.join(',');
        else if (include)
            data.include = include;
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/share/email',
            type: 'POST',
            data: data
        }).success(function (data) {
                self.attributes.events.push({
                    type: 'SHARE',
                    comment: emailAddress,
                    userId: Core.client.me.get('id'),
                    eventTimeStamp: new Date().getTime(),
                    internal: true
                });
            });

    },

    putItem: function(item) {
        if (! ('uuid' in item))
            item.uuid = Core.randomUUID();
        if (this.get('items').indexOf(item) < 0)
            this.get('items').push(item);
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/item/' + item.uuid,
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(item)
        });
    },

    deleteItem: function(item) {
        var index = this.get('items').indexOf(item);
        if (index >= 0)
            this.get('items').splice(index, 1);
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/item/' + item.uuid,
            type: 'DELETE'
        });
    },

    fetchItemPrices: function(options) {
        var params = {};
        if (this.get('totalWeight')) params.weight = this.get('totalWeight');
        if (this.get('totalDistance')) params.distance = this.get('totalDistance');
        var self = this;
        return Core.client.fetchItemPrices(this.get('items'), this.get('customerId'), this.get('customerProjectId'), params).success(function(items) {
            self.set({items: items}, options)
        });
    },

    attachDocument: function(documentId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'PUT'
        });
    },

    detachDocument: function(documentId) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'DELETE'
        }).success(function() {
            self.set({documents: _(self.get('documents')).filter(function(d) { return d.id != documentId && d.uuid != documentId })}, { silent: true });
            self.trigger('detachDocument', self);
        });
    },

    sendBroadcast: function(content) {
        var data = { content: content };
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/broadcast',
            type: 'POST',
            data: data
        }).success(function (data) {
            self.attributes.events.push({
                type: 'BROADCAST',
                userId: Core.client.me.get('id'),
                eventTimeStamp: new Date().getTime(),
                internal: true
            });
        });

    },

    allDestinationsValid: function() {
        if (!this.get('destinations') || this.get('destinations').length == 0) return false;
        var valid = true;
        _(this.get('destinations')).each(function (destination) {
            if (!destination.location || !destination.location.latitude || !destination.location.longitude) valid = false;
        });
        return valid;
    },

    clearRoute: function() {
        _(this.get('destinations')).each(function (destination) {
            delete destination.routeData;
            delete destination.routed;
            delete destination.routeTime;
            delete destination.routeDistance;
        });
    },

    clearDestinationRoute: function(destination) {
        if (typeof destination == 'number')
            destination = this.get('destinations')[destination];
        if (!destination) return;

        delete destination.routeData;
        delete destination.routed;
        delete destination.routeTime;
        delete destination.routeDistance;
        var index = this.get('destinations').indexOf(destination);
        if (index >= 0 && index < this.get('destinations').length-1) {
            var nextDestination = this.get('destinations')[index+1];
            delete nextDestination.routeData;
            delete nextDestination.routed;
            delete nextDestination.routeTime;
            delete nextDestination.routeDistance;
        }
    },

    getFirstArrivalTime: function() {
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 0; i < destinations.length; i++)
            if (destinations[i].arrivalTime) return destinations[i].arrivalTime;
        return null;
    },

    getFirstCalculatedArrivalTime: function() {
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 0; i < destinations.length; i++)
            if (destinations[i].calculatedArrivalTime) return destinations[i].calculatedArrivalTime;
        return null;
    },

    getTotalRouteTime: function() {
        var totalTime = 0;
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 1; i < destinations.length; i++) {
            var destination = destinations[i];
            if (typeof destination.routeTime != 'number') return null;
            totalTime += destination.routeTime;
        }
        return totalTime;
    },

    getTotalStopDuration: function() {
        var totalTime = 0;
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 0; i < destinations.length; i++) {
            var destination = destinations[i];
            totalTime += destination.stopDuration || 0;
        }
        return totalTime;
    },

    getTotalRouteDistance: function() {
        var totalDistance = 0;
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 1; i < destinations.length; i++) {
            var destination = destinations[i];
            if (typeof destination.routeDistance != 'number') return null;
            totalDistance += destination.routeDistance;
        }
        return totalDistance;
    },

    getStraightLineDistance: function() {
        var totalDistance = 0;
        var destinations = this.get('destinations');
        if (!destinations) return null;
        for (var i = 1; i < destinations.length; i++) {
            var destination = destinations[i];
            var previous = destinations[i-1];
            if (!destination.location || !destination.location.latitude || !destination.location.longitude) return null;
            if (!previous.location || !previous.location.latitude || !previous.location.longitude) return null;
            totalDistance += distanceSloc(destination.location.latitude, destination.location.longitude, previous.location.latitude, previous.location.longitude, GMEAN_EARTH_RADIUS_METERS);
        }
        return totalDistance;

    },

    getRouteDescription: function() {
        var destinations = this.get('destinations');
        if (destinations && destinations.length > 0) {
            var text;
            if (destinations.length == 1) {
                if (destinations[0].location)
                    text = destinations[0].location.placeName;
            } else {
                for (var i = 1; i < destinations.length; i++) {
                    var destination = destinations[i];
                    if (destination.location && destination.location.placeName) {
                        text = destination.location.placeName;
                        break;
                    }
                }
                if (destinations.length > 2 && text)
                    text += ' (+' + (destinations.length-2) + ')';
            }
            return text;
        }
    },

    myFollower: function() { return _(this.get('followers')).findWhere({userId: Core.client.me.id}); },
    myFollowerOptions: function() {
        var myOptions = [];
        if (!Core.client.groups || !this.get('groupId')) return null;
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return null;
        var myFollower = this.myFollower();
        if (myFollower) {
            if (myFollower.options != null)
                return myFollower.options;
            else
                return ['states', 'discrepancies']; // Follower.DEFAULT_OPTIONS
        }
        var groupOptions = group.get('followerOptions');
        if (groupOptions['jobCreator'] && Core.client.me.id == this.get('creatorId')) {
            var options = groupOptions['jobCreator'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['jobResource'] && this.myResource()) {
            var options = groupOptions['jobResource'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['jobDispatcher'] && _(this.get('resources')).findWhere({dispatcherId: Core.client.me.id})) {
            var options = groupOptions['jobDispatcher'];
            if (myOptions.length < options.length) myOptions = options;

        }
        if (groupOptions['jobOrderer'] && Core.client.me.id == this.get('ordererId')) {
            var options = groupOptions['jobOrderer'];
            if (myOptions.length < options.length) myOptions = options;

        }
        if (groupOptions['jobGroupOrderAdmin'] && Core.client.hasGroupPermission(this.get('groupId'), 'admin_orders') && Core.client.hasPermission('create_orders')) {
            var options = groupOptions['jobGroupOrderAdmin'];
            if (myOptions.length < options.length) myOptions = options;
        }
        return _(myOptions).uniq();
    },

    sortTime: function() {
        if (this.get('startTime')) return this.get('startTime');
        var destinations = this.get('destinations');
        if (destinations) {
            for (var i = 0; i < destinations.length; i++) {
                if (destinations[i].arrivalTime) return destinations[i].arrivalTime;
            }
            for (var i = 0; i < destinations.length; i++) {
                if (destinations[i].calculatedArrivalTime) return destinations[i].calculatedArrivalTime;
            }
        }
        if (this.get('startedTimeStamp')) return this.get('startedTimeStamp');
        if (this.get('endTime')) return this.get('endTime');
        if (this.get('endedTimeStamp')) return this.get('endedTimeStamp');
        return null;
    },

    sortTimeIncludingUntimed: function() {
        var sortTime = this.sortTime();
        if (sortTime) return sortTime;
        var state = this.get('state');
        if (state == 'FINISHED' || state == 'CANCELED' || state == 'CLOSED') return 0;
        if (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED' || state == 'DAY_FINISHED') return new Date().getTime();
        return this.get('creationTimeStamp') * 10; // always top of the list but still sorted according to creation time
    },

});

var Jobs = Backbone.Collection.extend({
	model: Job,
	
	url: function() {
		if (this.customUrl)
			return this.customUrl;

		// Build query from filter parameters
		var url;
		var params = { };

        if (this.filterParams.states) {
            if (typeof this.filterParams.states == 'object')
                params.states = this.filterParams.states.join(',');
            else
                params.states = this.filterParams.states;
        } else {
            if (this.filterParams.includeFinished) params.finished = '';
            if (this.filterParams.includeCanceled) params.canceled = '';
            if (this.filterParams.includeDrafts) params.drafts = '';
            if (this.filterParams.includeClosed) params.closed = '';
        }
        if (this.filterParams.onlyInvoiced) params.onlyinvoiced = '';

        if (this.searchQuery) {
            url = Core.contextRoot + '/job/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/job/list' + (this.filterParams.path ? '/'+this.filterParams.path : '');
            if (this.filterParams.path == 'templates') {
                url = Core.contextRoot + '/job/templates';
                if (this.filterParams.includeDrafts) params.drafts = true;
                if (this.filterParams.onlyEditable) params.only_editable = true;
            } else {
                if (this.filterParams.path == 'user' && this.filterParams.userId) url += '/' + this.filterParams.userId;
                if (this.filterParams.includeUntimed) params.untimed = '';
                if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
                    var fromDate = new Date();
                    fromDate.setHours(0,0,0,0);
                    this.filterParams.from = fromDate.getTime();
                }
                params.from = this.filterParams.from;
                if (this.filterParams.to)
                    params.to = this.filterParams.to;
                if ('includeCurrent' in this.filterParams) {
                    if (this.filterParams.includeCurrent)
                        params.current = '';
                } else if (!this.filterParams.from && !this.filterParams.to)
                    params.current = '';
            }
            if (this.filterParams.params) _(params).extend(this.filterParams.params);
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;
        if (this.filterParams.offset) params.offset = this.filterParams.offset;
		var paramsStr = $.param(params);
		if (paramsStr.length > 0) url += '?' + paramsStr;
		return url;
	},
	
	initialize: function(models, options) {
		this.filterParams = {
			path: 'all',
            states: ['DRAFT','PUBLISHED','ASSIGNED','SCHEDULED','ACCEPTED','IN_TRANSIT','ON_STATION','STARTED','PAUSED','DAY_FINISHED','FINISHED','CANCELED'],
			includeCurrent: true,
			includeUntimed: true,
			from: null,
			to: null,
			userId: null
		};
        if (options && options.history)
            this.filterParams.states.push('CLOSED');
		if (options && options.url)
			this.customUrl = options.url;
		else if (options && options.filterParams)
			_(this.filterParams).extend(options.filterParams);
		else if ((!options || !options.temporary) && ('jobs.filterParams' in sessionStorage))
			this.filterParams = JSON.parse(sessionStorage['jobs.filterParams']);
		if (! 'path' in this.filterParams)
			this.filterParams.path = 'all';
	},
	
	saveFilter: function() {
		sessionStorage['jobs.filterParams'] = JSON.stringify(this.filterParams);
	},

    loadFilter: function() {
        this.filterParams = JSON.parse(sessionStorage['jobs.filterParams']);
    },

    forEachRelevantTemplate: function(callback, context) {
        var limitToPrimaryGroup = Core.client.me.get('shareAllWithPrimaryGroup') && this.filter(function (template) {
                return template.isValidForGroup(Core.client.me.get('primaryGroupId')) && Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_jobs');
            }).length > 0;
        return this.each(function (template) {
            var defaultTemplate = Core.client.primaryGroup() && Core.client.primaryGroup().getOrGetInParentGroup('defaultJobTemplateId') == template.id;
            if (template.get('groupId') && !Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_jobs')) return;
            if (limitToPrimaryGroup && !template.isValidForGroup(Core.client.me.get('primaryGroupId'))) return;
            callback.apply(context, [template, defaultTemplate]);
        });
    },

});

Jobs.filterCollection = function(filter, collection) {
    var filter = _(filter).clone();
    if (!filter.list) filter.list = 'all';
    var userId;
    var vehicleId;
    var assetTypeId;
    var label;
    if (filter.list == 'mine') {
        userId = Core.client.me.id;
        delete filter.where;
    } else if (filter.list.indexOf('user-') == 0) {
        userId = parseInt(filter.list.replace('user-', ''));
        delete filter.where;
    } else if (filter.list.indexOf('label-') == 0) {
        label = filter.list.replace('label-', '');
        delete filter.where;
    } else if (filter.list.indexOf('vehicle-') == 0) {
        vehicleId = parseInt(filter.list.replace('vehicle-', ''));
        delete filter.where;
    } else if (filter.list.indexOf('activity-') == 0) {
        filter.where = { activityId: parseInt(filter.list.replace('activity-','')) };
    } else if (filter.list.indexOf('group-') == 0) {
        filter.where = { groupId: parseInt(filter.list.replace('group-','')) };
    } else if (filter.list.indexOf('customer-') == 0) {
        filter.where = { customerId: parseInt(filter.list.replace('customer-', '')) };
    } else if (filter.list.indexOf('asset-') == 0) {
        filter.where = {assetId: parseInt(filter.list.replace('asset-', ''))};
    } else if (filter.list.indexOf('assettype-') == 0) {
        assetTypeId = parseInt(filter.list.replace('assettype-', ''));
        delete filter.where;
    } else if (filter.list == 'not-in-route') {
        filter.where = {routeId: null};
    } else if (filter.list == 'all' || filter.list == 'unassigned' || filter.list == 'today' || filter.list == 'in-route' || filter.list == 'late' || filter.list == 'ongoing') {
        delete filter.where;
    } else {
        console.error('Unknown filter selection ' + filter.list);
    }

    var filteredCollection = new Jobs(filter.where ? collection.where(filter.where) : collection.models);
    if (userId) {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            var resource = job.getResource(userId);
            if (!resource) return false;
            // filter resource states
            if (resource.state == 'DECLINED') return false;
            if ((resource.state == 'ACCEPTED' || resource.state == 'INACTIVE')) {
                if (collection.filterParams.states.indexOf('SCHEDULED') < 0)
                    return false;
            } else if (collection.filterParams.states.indexOf(resource.state) < 0)
                return false;
            return true;
        }, this), {silent: true});
    } else if (vehicleId) {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            return job.getResourceByVehicle(vehicleId);
        }, this), {silent: true})
    } else if (assetTypeId) {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            var asset = Core.client.assets.get(job.get('assetId'));
            return asset && asset.get('assetTypeId') == assetTypeId;
        }, this), {silent: true})
    } else if (label) {
        filteredCollection.reset(filteredCollection.filter(function (order) {
            return order.get('labels') && order.get('labels').indexOf(label) >= 0;
        }, this), { silent: true })
    } else if (filter.list == 'unassigned') {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            if (job.get('state') == 'FINISHED' || job.get('state') == 'CANCELED' || job.get('state') == 'CLOSED') return false;
            if (job.get('routeId')) return false;
            var schedulable = (!job.get('groupId') && job.get('creatorId') == Core.client.me.id) || Core.client.hasGroupPermission(job.get('groupId'), 'dispatch');
            if (schedulable) {
                var resources = job.get('resources');
                if (!resources || resources.length == 0) return true;
                var scheduled = true;
                _(resources).each(function (resource) {
                    if (!job.isResourceScheduled(resource)) scheduled = false;
                }, this);
                return !scheduled;
            } else {
                return job.get('selfScheduled') && job.get('state') == 'PUBLISHED';
            }
        }, this), { silent: true });
    } else if (filter.list == 'today') {
        filteredCollection.reset(filteredCollection.filter(function (job) {
            var todayTime = Date.today().getTime();
            var tomorrowTime = Date.today().addDays(1).getTime();
            if (job.get('creationTimeStamp') && !job.get('startTime') && job.get('creationTimeStamp') >= todayTime && job.get('creationTimeStamp') < tomorrowTime) return true;
            if (job.get('startTime') && job.get('startTime') >= todayTime && job.get('startTime') < tomorrowTime) return true;
            if (job.get('endTime') && job.get('endTime') >= todayTime && job.get('endTime') < tomorrowTime) return true;
            if (job.get('startTime') && job.get('endTime') && job.get('endTime') >= todayTime && job.get('startTime') < tomorrowTime) return true;
            if (!job.get('startTime') && !job.get('endTime') && job.get('startedTimeStamp') && job.get('startedTimeStamp') >= todayTime && job.get('startedTimeStamp') < tomorrowTime) return true;
            if (!job.get('startTime') && !job.get('endTime') && job.get('endedTimeStamp') && job.get('endedTimeStamp') >= todayTime && job.get('endedTimeStamp') < tomorrowTime) return true;
        }, this), { silent: true });
    } else if (filter.list == 'in-route') {
        filteredCollection.reset(filteredCollection.filter(function (job) { return job.get('routeId'); }), { silent: true });
    } else if (filter.list == 'late') {
        filteredCollection.reset(filteredCollection.filter(function (job) { return job.isLate(); }), { silent: true });
    } else if (filter.list == 'ongoing') {
        filteredCollection.reset(filteredCollection.filter(function (job) { return ['IN_TRANSIT', 'ON_STATION', 'STARTED', 'PAUSED', 'DAY_FINISHED'].indexOf(job.get('state')) >= 0; }), { silent: true });
    }
    // filter out deletes and state changes from updates
    filteredCollection.reset(filteredCollection.filter(function (job) {
        if (collection.filterParams && collection.filterParams.states && collection.filterParams.states.indexOf(job.get('state')) < 0) return false;
        return !job.get('deleted');
    }), { silent: true });
    if (collection.filterParams && collection.filterParams.activities && Core.client.activities != null && Core.client.activities.length > 0)
        filteredCollection.reset(filteredCollection.filter(function (job) {
            if (collection.filterParams.activities.indexOf(job.get('activityId')) < 0)
                return false;
            return true;
        }), {silent: true});
    return filteredCollection;
};
