"use strict";

Core.Trip = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/trip'
});

Core.Trips = Backbone.Collection.extend({
    model: Core.Trip,

    initialize: function(models, options) {
        if (options) this.filterParams = options.filterParams;
        if (!this.filterParams) this.filterParams = {};
    },

    url: function() {
        var path = this.filterParams.path || 'user/me';
        var params = this.filterParams.params || {};
        return Core.Trip.prototype.urlRoot + (path ? '/' + path : '') + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');

    }
});
