"use strict";

Core.Asset = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/asset',
    editGroupPermission: 'admin_assets',

    defaults: function() {
        return {
            active: true,
            documents: [],
            labels: []
        };
    },

    isStateAlterable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        return this.isNew() || Core.client.hasPermission('edit_all_data') || Core.client.hasGroupPermission(this.get('groupId'), 'alter_asset_state');
    },

    isDispatchable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        return this.isNew() || Core.client.hasPermission('edit_all_data') || Core.client.hasGroupPermission(this.get('groupId'), 'dispatch_assets');
    },

    setAssetType: function(assetType, options) {
        if (assetType) {
            this.set({
                assetTypeId: assetType.id,
                assetTypeName: assetType.get('name')
            }, options);
        } else {
            this.set({
                assetTypeId: null,
                assetTypeName: null
            }, options);
        }
    },

    setFromTemplate: function(template, previousTemplate, options) {
        var attributes = _(this.attributes).clone();
        var previousCustomFields = attributes.customFields;
        if (!this.savedCustomFields) this.savedCustomFields = [];
        _(attributes.customFields).each(function (field) {
            var existingField = _(this.savedCustomFields).findWhere({name: field.name});
            if (existingField)
                existingField.value = field.value;
            else
                this.savedCustomFields.push(_(field).clone());
        }, this);
        attributes.customFields = [];
        if (template)
            _(template.customFields).each(function (field) { attributes.customFields.push(_(field).clone()); });
        if (previousCustomFields && attributes.customFields) {
            _(previousCustomFields).each(function (prefield) {
                var tempfield = previousTemplate && _(previousTemplate.customFields).findWhere({name: prefield.name});
                if (tempfield && tempfield.value == prefield.value) return;

                var postfield = _(attributes.customFields).findWhere({name: prefield.name});
                if (!postfield) postfield = _(attributes.customFields).findWhere({label: prefield.label});
                if (postfield) {
                    postfield.value = prefield.value;
                }
            }, this);
        }
        _(this.savedCustomFields).each(function (field) {
            if (!field.value) return;
            var postfield = _(attributes.customFields).findWhere({name: field.name});
            if (!postfield) postfield = _(attributes.customFields).findWhere({label: field.label});
            if (postfield && !postfield.value) postfield.value = field.value;
        }, this);

        if (previousTemplate && attributes.documents) {
            _(previousTemplate.documents).each(function(document) {
                attributes.documents = _(attributes.documents).without(_(attributes.documents).findWhere({contentUrl: document.contentUrl}));
            });
        }
        if (template && template.documents) {
            if (!attributes.documents) {
                attributes.documents = template.documents;
                _(attributes.documents).each(function (document) { delete document.id; delete document.uuid; });
            } else {
                _(template.documents).each(function(document) {
                    if (!_(attributes.documents).findWhere({contentUrl: document.contentUrl})) {
                        var doc = _(document).clone();
                        delete doc.id; delete doc.uuid;
                        attributes.documents.push(doc);
                    }
                });
            }
        }

        if (template && previousTemplate) {
            if (attributes.state == previousTemplate.state) attributes.state = template.state;
            if (attributes.customerId == previousTemplate.customerId) attributes.customerId = template.customerId;
            if (attributes.customerProjectId == previousTemplate.customerProjectId) attributes.customerProjectId = template.customerProjectId;
            //if (attributes.location.placeName == previousTemplate.location.placeName) attributes.location = _(template.location).clone();
            if (attributes.locationDescription == previousTemplate.description) attributes.locationDescription = template.locationDescription;
            if (attributes.description == previousTemplate.description) attributes.description = template.description;
            if (attributes.stateComment == previousTemplate.stateComment) attributes.stateComment = template.stateComment;
        } else if (template) {
            if (!this.id || !attributes.state) attributes.state = template.state;
            if (!attributes.customerId) attributes.customerId = template.customerId;
            if (!attributes.customerProjectId) attributes.customerProjectId = template.customerProjectId;
            if (!attributes.location) attributes.location = template.location;
            if (!attributes.locationDescription) attributes.locationDescription = template.locationDescription;
            if (!attributes.description) attributes.description = template.description;
            if (!attributes.stateComment) attributes.stateComment = template.stateComment;
        }
        if (!attributes.labels) attributes.labels = [];
        if (template && template.labels) {
            _(template.labels).each(function (label) {
                if (attributes.labels.indexOf(label) < 0) attributes.labels.push(label);
            });
        }

        this.set(attributes, options);
    },

});

Core.Assets = Backbone.Collection.extend({
    model: Core.Asset,
    MAX_SEARCH_RESULTS: Core.SEARCH_RESULTS_LIMIT,
    canPaginate: true,
    url: function() {
        var path = '';
        var params = {};
        if (this.filterParams) {
            params = this.filterParams.params || {};
            if (typeof this.filterParams.path !== 'undefined') path = '/' + this.filterParams.path;
            if (this.filterParams.groups) params.groups = this.filterParams.groups;
            else if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
        }
        if (this.filterParams.states) {
            if (typeof this.filterParams.states == 'object')
                params.states = this.filterParams.states.join(',');
            else
                params.states = this.filterParams.states;
        }
        if (this.searchQuery) {
            params.q = this.searchQuery;
            path = '/search';
            params.limit = this.MAX_SEARCH_RESULTS;
        }
        return Core.Asset.prototype.urlRoot + path + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    },
    initialize: function(models, options) {
        this.filterParams = (options && options.filterParams) || {};
    }
});

Core.AssetType = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/asset/type',
    editGroupPermission: 'admin_assets',

    defaults: function() {
        return {
            groupId: Core.client.me.get('primaryGroupId')
        };
    },

    getOrFetchDetail: function(callback) {
        if (this.get('assetTemplate')) {
            if (callback) callback();
        } else {
            return this.fetch().success(callback);
        }
    },

    destroyIncludingAssets: function() {
        var self = this;
        return $.ajax({
            url: this.url() + (this.url().indexOf('?') >= 0 ? '&' : '?') + 'deleteassets=1',
            type: 'DELETE'
        }).success(function (response) {
            if (self.collection) self.collection.remove(self);
        });
    },

});

Core.AssetTypes = Backbone.Collection.extend({
    model: Core.AssetType,
    url: function() {
        var path = '';
        var params = {};
        if (this.filterParams) {
            params = this.filterParams.params || {};
            if (typeof this.filterParams.path !== 'undefined') path = '/' + this.filterParams.path;
            if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
        }
        if (this.searchQuery) params.q = this.searchQuery;
        return Core.AssetType.prototype.urlRoot + path + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    },

    initialize: function(models, options) {
        if (options) this.filterParams = options.filterParams;
    },
});

Core.Assets.filterCollection = function(filter, collection) {
    var filteredCollection = new Core.Assets(collection.models);
    if (collection.searchQuery) return filteredCollection;
    if (filter) {
        if (filter.where)
            filteredCollection = new Core.Assets(filteredCollection.where(filter.where));
        if (filter.states) {
            var states = filter.states;
            filteredCollection = new Core.Assets(filteredCollection.filter(function (asset) {
                var state = asset.get('state');
                if (!state || state == 'INITIAL') state = 'none';
                return states.indexOf(state) >= 0;
            }));
        }
    }
    return filteredCollection;
};

Core.AssetAvailability = Backbone.Collection.extend({
    initialize: function(options) {
        this.filterParams = {};
        var model = options.reservation || options.job;
        if (options.assetId)
            this.path = options.assetId;
        else if (model && model.get('assetId'))
            this.path = model.get('assetId');
        else
            this.path = 'type/' + (options.assetTypeId || 'null');
        if (model) {
            this.filterParams.from = model.get('startTime');
            if (model.get('endTime'))
                this.filterParams.to = model.get('endTime');
            else if (model.get('duration') && !model.get('allDay'))
                this.filterParams.to = this.filterParams.from + model.get('duration') * 60000;
            else
                this.filterParams.to = this.filterParams.from;
            if (!this.filterParams.from && this.filterParams.to) this.filterParams.from = this.filterParams.to;
            if (model.get('allDay'))
                this.filterParams.to += 24*3600000;
            this.filterParams.group = model.get('groupId');
            if (model.id) this.filterParams.exclude = model.id;
        } else {
            this.filterParams.from = options.from;
            this.filterParams.to = options.to;
            this.filterParams.group = options.groupId;
        }
    },
    url: function() {
        return Core.contextRoot + '/asset/availability' + (this.path ? '/' + this.path : '') + '?' + $.param(this.filterParams);
    },
});
