"use strict";

var ExtensionService = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/service',
});
var ExtensionServices = Backbone.Collection.extend({
    model: ExtensionService,
    url: Core.contextRoot + '/service',
});
var ExtensionServiceAccount = Backbone.Model.extend({});
var ExtensionServiceAccounts = Backbone.Collection.extend({
    model: ExtensionServiceAccount,
    url: Core.contextRoot + '/user/me/serviceaccount'
})
