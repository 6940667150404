"use strict";

Core.Document = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/document',
    idAttribute: 'uuid'
});

Core.Documents = Backbone.Collection.extend({
    model: Core.Document
});
