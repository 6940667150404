"use strict";

var JobSummary = Backbone.Collection.extend({

    entity: 'Job',

    url: function() {
        if (this.customUrl)
            return this.customUrl;

        // Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.onlyInvoiced) params.invoiced = '';

        url = Core.contextRoot + '/job/summary' + (this.filterParams.path ? '/'+this.filterParams.path : '');
        if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
            var fromDate = new Date();
            fromDate.setHours(0,0,0,0);
            fromDate.setDate(1);
            this.filterParams.from = fromDate.getTime();
        }
        params.from = this.filterParams.from;
        if (this.filterParams.to)
            params.to = this.filterParams.to;
        var paramsStr = $.param(params);
        if (paramsStr.length > 0)
            url += '?' + paramsStr;
        return url;
    },

    initialize: function(models, options) {
        this.filterParams = options && options.filterParams || {};
    },

    combinedSummaryBy: function(attribute) {
        var combinedModels = [];
        var combinedModelByKey = {}
        this.each(function(model) {
            var key = model.get(attribute);
            var combinedModel = combinedModelByKey[key];
            if (!combinedModel) {
                combinedModel = model.clone();
                combinedModelByKey[key] = combinedModel;
                combinedModels.push(combinedModel);
            } else {
                _(Object.keys(combinedModel.attributes)).each(function (key) {
                    if (key == 'count' || key == 'totalWeight' || key == 'totalDistance' || key == 'totalInvoicedPrice') return;
                    if (combinedModel.get(key) != model.get(key))
                        combinedModel.set(key, null);
                }, this);
                if (model.get('count')) combinedModel.set('count', combinedModel.get('count') ? combinedModel.get('count') + model.get('count') : model.get('count'));
                if (model.get('totalWeight')) combinedModel.set('totalWeight', combinedModel.get('totalWeight') ? combinedModel.get('totalWeight') + model.get('totalWeight') : model.get('totalWeight'));
                if (model.get('totalDistance')) combinedModel.set('totalDistance', combinedModel.get('totalDistance') ? combinedModel.get('totalDistance') + model.get('totalDistance') : model.get('totalDistance'));
                var invoicedPrice = model.get('totalInvoicedPrice');
                if (invoicedPrice == null) combinedModel.set('totalInvoicedPrice', null);
                else if (invoicedPrice && combinedModel.get('totalInvoicedPrice') !== null) combinedModel.set('totalInvoicedPrice', combinedModel.get('totalInvoicedPrice') ? combinedModel.get('totalInvoicedPrice') + invoicedPrice : invoicedPrice);
            }
        }, this);
        return new JobSummary(combinedModels);
    },

    subSummaryWhere: function(selection) {
        return new JobSummary(this.where(selection));
    },

    sum: function(attribute) {
        var sum = 0;
        this.each(function (entry) { sum += entry.get(attribute) ? entry.get(attribute) : 0; });
        return sum;
    },
    totalWeight: function() { return this.pluck('totalWeight').indexOf(null) < 0 ? this.sum('totalWeight') : undefined; },
    totalDistance: function() { return this.pluck('totalDistance').indexOf(null) < 0 ? this.sum('totalDistance') : undefined; },
    totalInvoicedPrice: function() { return this.pluck('totalInvoicedPrice').indexOf(null) < 0 ? this.sum('totalInvoicedPrice') : undefined; },
    currency: function() {
        var currencies = [];
        this.each(function (model) {
            if (model.get('currency') == null && model.get('groupId')) {
                var group = Core.client.groups.get(model.get('groupId'));
                currencies.push(group && group.get('defaultCurrency') || null);
            } else
                currencies.push(model.get('currency'));
        }, this);
        currencies = _(currencies).uniq();
        if (currencies.length == 1) return currencies[0];
    },
});

