"use strict";

var User = Core.User.extend({

    addExtensionServiceAccount: function(name) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/serviceaccount/' + name,
            type: 'POST'
        }).success(function (account) {
            self.get('extensionServices').push(account);
            self.trigger('change', self);
        });
    },

    removeExtensionServiceAccount: function(name) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/serviceaccount/' + name,
            type: 'DELETE'
        }).success(function () {
            var account = _(self.get('extensionServices')).findWhere({name: name});
            if (account) self.get('extensionServices').splice(self.get('extensionServices').indexOf(account), 1);
            self.trigger('change', self);
        });
    },

    setExtensionServiceExpiration: function(name, expires, trialPeriod) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/serviceaccount/' + name + '/expiration',
            type: 'PUT',
            data: {
                expires: expires,
                trial: trialPeriod
            }
        }).success(function(updatedAccount) {
            var account = _(self.get('extensionServices')).findWhere({name: name});
            account.expires = updatedAccount.expires;
            account.trialPeriod = updatedAccount.trialPeriod;
        });
    },

    removeExtensionServiceExpiration: function(name) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/serviceaccount/' + name + '/expiration',
            type: 'DELETE'
        }).success(function(updatedAccount) {
            var account = _(self.get('extensionServices')).findWhere({name: name});
            account.expires = updatedAccount.expires;
            account.trialPeriod = updatedAccount.trialPeriod;
            self.trigger('change', self);
        });
    },

    resetAcceptedLicense: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/accepted-license',
            type: 'DELETE'
        }).success(function() {
            self.set({acceptedLicenseVersion: null, acceptedLicenseLocale: null});
        });
    },

    changePassword: function(password, oldPassword, forgottenToken) {
        var params = { 'new': password };
        if (oldPassword) params.old = oldPassword;
        if (forgottenToken) params.forgotten = forgottenToken;
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/password?' + $.param(params),
            type: 'PUT',
            success: function() {
                self.set({passwordSet: true}, {silent: true});
            }
        });
    },

    sendForgottenPassword: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/password/forgot',
            type: 'POST',
            success: function() {
                self.set({passwordSet: true}, {silent: true});
            }
        });
    },

    generateAndSendPassword: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/password/generate',
            type: 'POST',
            success: function() {
                self.set({passwordSet: true}, {silent: true});
            }
        });

    },

    deletePassword: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/password',
            type: 'DELETE',
            success: function() {
                self.set({passwordSet: false}, {silent: true});
            }
        });
    },

    deleteFederatedIdentity: function(id) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/federated-identity/' + id,
            type: 'DELETE',
            success: function() {
                var fedIds = self.get('federatedIdentities');
                var fedId = _(fedIds).findWhere({id: id});
                fedIds.splice(fedIds.indexOf(fedId), 1);
            }
        });
    },

    fetchPermanentToken: function() {
        return $.get(Core.contextRoot + '/auth/permanenttoken/' + this.id);
    },
    generatePermanentToken: function() {
        return $.ajax({
            url: Core.contextRoot + '/auth/permanenttoken/' + this.id,
            type: 'POST'
        });
    },
    revokePermanentToken: function() {
        return $.ajax({
            url: Core.contextRoot + '/auth/permanenttoken/' + this.id,
            type: 'DELETE'
        });
    },

    fetchRoles: function() {
        var self = this;
        return $.getJSON(this.urlRoot + '/' + this.id + '/role', function(roles) { self.roles = roles; });
    },
    addRole: function(roleName) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/role/' + roleName,
            type: 'PUT',
            success: function(roles) { self.roles = roles; }
        });
    },
    removeRole: function(roleName) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/role/' + roleName,
            type: 'DELETE',
            success: function(roles) { self.roles = roles; }
        });
    },

    fetchPermissions: function() {
        var self = this;
        return $.getJSON(this.urlRoot + '/' + this.id + '/permission', function(permissions) { self.permissions = permissions; });
    },
    savePermissions: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/permission',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(self.permissions),
            success: function(permissions) { self.permissions = permissions; }
        });
    }
});

var Users = Backbone.Collection.extend({
    url: Core.Users.prototype.url,
    model: User
});

var UserAvailability = Backbone.Collection.extend({
    initialize: function(options) {
        this.filterParams = {};
        if (options.job) {
            this.filterParams.from = options.job.get('startTime');
            if (options.job.get('endTime'))
                this.filterParams.to = options.job.get('endTime');
            else if (options.job.get('duration') && !options.job.get('allDay'))
                this.filterParams.to = this.filterParams.from + options.job.get('duration') * 60000;
            else
                this.filterParams.to = this.filterParams.from;
            if (!this.filterParams.from && this.filterParams.to) this.filterParams.from = this.filterParams.to;
            if (options.job.get('allDay'))
                this.filterParams.to += 24*3600000;
            this.filterParams.group = options.job.get('groupId');
        } else {
            this.filterParams.from = options.from;
            this.filterParams.to = options.to;
            this.filterParams.group = options.groupId;
        }
    },
    url: function() {
        return Core.contextRoot + '/user/availability?' + $.param(this.filterParams);
    },
});



var Role = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/sys/role'
});
var Roles = Backbone.Collection.extend({
    url: Core.contextRoot + '/sys/role',
    model: Role
});

var BillingUser = Backbone.Model.extend({
    urlRoot: '/billing/user',
    idAttribute: 'uuid'
});

