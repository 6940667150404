"use strict";

if (typeof Map == 'undefined') var Map = {};

Map.GMEAN_EARTH_RADIUS_METERS = 6367450;
Map.GMEAN_EARTH_RADIUS_KM = 6367.45;
Map.GMEAN_EARTH_RADIUS_MILES = 3956.55;
Map.GMEAN_EARTH_RADIUS_NM = 3438.15;

// Spherical law of cosines
Map.distanceSloc =function(lat1, lon1, lat2, lon2, R) {
    if (!R)
        R = Map.GMEAN_EARTH_RADIUS_METERS;
    var rlat1 = lat1 * Math.PI/180;
    var rlon1 = lon1 * Math.PI/180;
    var rlat2 = lat2 * Math.PI/180;
    var rlon2 = lon2 * Math.PI/180;
    var d = Math.acos(Math.sin(rlat1)*Math.sin(rlat2) +
        Math.cos(rlat1)*Math.cos(rlat2) *
            Math.cos(rlon2-rlon1)) * R;
    return d;
}
