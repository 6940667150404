"use strict";

if (typeof Map == 'undefined') var Map = {};

Map.Track = Backbone.Model.extend({

    initialize: function(attributes, options) {
        if (options) {
            this.path = options.path;
            this.params = options.params || {};
            if (options.from)
                this.params.from = options.from;
            if (options.to)
                this.params.to = options.to;
            if (options.latest) this.params.latest = '';
        } else
            this.params = {};
        if (!this.params.from) this.params.from = '-1d';
    },

    url: function() {
        return Core.contextRoot + '/track/' + this.path + '?' + $.param(this.params);
    },

    getTotalTime: function() {
        var timeStamps = this.get('timeStamps');
        if (timeStamps.length < 2) return 0;
        return timeStamps[timeStamps.length-1] - timeStamps[0];
    },

    getTotalDistance: function() {
        var coordinates = this.get('coordinates');
        if (!coordinates || coordinates.length < 2) return 0;
        var totalDistance = 0;
        for (var i = 1; i < coordinates.length; i++) {
            var d = Map.distanceSloc(coordinates[i-1][0], coordinates[i-1][1], coordinates[i][0], coordinates[i][1]);
            if (!isNaN(d))
                totalDistance += d;
        }
        return totalDistance;
    },

    getTotalDistanceWithinTime: function(from, to) {
        var coordinates = this.get('coordinates');
        if (!coordinates || coordinates.length < 2) return 0;
        var timeStamps = this.get('timeStamps');
        if (timeStamps.length < 2) return 0;
        var totalDistance = 0;
        for (var i = 1; i < coordinates.length; i++) {
            if (timeStamps[i] <= from) continue;
            if (timeStamps[i] > to) break;
            var d = Map.distanceSloc(coordinates[i-1][0], coordinates[i-1][1], coordinates[i][0], coordinates[i][1]);
            if (!isNaN(d))
                totalDistance += d;
        }
        return totalDistance;
    },

    getTotalTimeBelowSpeed: function(threshold) {
        var speeds = this.get('speeds');
        var timeStamps = this.get('timeStamps');
        if (!speeds || speeds.length < 2)
            return 0;
        var totalTime = 0;
        for (var i = 0; i < speeds.length-1; i++) {
            if (speeds[i] < threshold)
                totalTime += timeStamps[i+1] - timeStamps[i];
        }
        return totalTime;
    },

    getTotalTimeAboveSpeed: function(threshold) {
        var speeds = this.get('speeds');
        var timeStamps = this.get('timeStamps');
        if (!speeds || speeds.length < 2)
            return 0;
        var totalTime = 0;
        for (var i = 0; i < speeds.length-1; i++) {
            if (speeds[i] >= threshold)
                totalTime += timeStamps[i+1] - timeStamps[i];
        }
        return totalTime;
    },
});

Map.Tracks = Backbone.Collection.extend({
    model: Map.Track,
});