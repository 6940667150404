//New, backbone powered version of CoreClient
if (typeof Core == 'undefined') Core = {};

"use strict";

//	Namespace Core with some static functions

if (!Core.contextRoot) Core.contextRoot = '/core';
Core.apiVersion = 4;

Core.SEARCH_RESULTS_LIMIT = 300;
Core.LIST_UPDATE_INTERVAL = 60000;

Core.initialize = function() {
    Core.client = new Core.Client();
    Core.client.initialize();

    window.onerror = function(m,u,l,c,err) {
        if (window.localStorage) {
            // Let's not repeat ourselves
            if (localStorage.lastErrorMessage && localStorage.lastErrorMessage == m) return false;
            localStorage.lastErrorMessage = m;
        }
        if (m && m.match("Script error") && l == 0) return false;
        if (m && m.match("Not enough storage")) return false;
        if (m && m.match("Slut p. minne")) return false;
        if (m && m.match("Det finns inte tillr.ckligt med utrymme")) return false;
        if (u && u.match("fineuploader") && m && m.match("querySelectorAll")) return false;

        var user = '?';
        try { user = Core.client.me.get('emailAddress'); } catch (e) {}

        jQuery.post(Core.contextRoot + '/jserror', {
            message: m,
            url: u,
            line: l + (c ? ':' + c : ''),
            window: window.location.href,
            userAgent: navigator.userAgent || '-',
            user: user,
            stack: err && err.stack ? err.stack : ''
        });
        return false
    };

    return Core.client;
};

Core.randomUUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
};

Core.getPdfUrl = function(url) {
    var match = document.cookie.match(/JSESSIONID=([^;]*)/);
    var sessionId;
    if (match && match.length >= 1) sessionId = match[1];
    if (!url) url = location.href;
    var params = { url: url };
    if (Core.apiToken) params.api_token = Core.apiToken;
    if (Core.client.me.get('timeZone')) params.tz = Core.client.me.get('timeZone');
    if (sessionId) params.sessionid = sessionId;
    return location.origin + '/vault/pdf?' + $.param(params);
};

Core.getContentTemplate = function(group, name, callback) {
    return $.get(Core.contextRoot + '/group/' + group + '/content-template/' + name + '/' + Core.language, callback);
};

Core.avatarUrl = function(model) {
    if (!model) return undefined;
    var url = typeof model.get == 'function' ? model.get('avatarUrl') : model.avatarUrl;
    if (!url) return url;
    if (url.indexOf('/') != 0 && url.indexOf('http') != 0) url = '//' + url;
    if (url.indexOf('/vault') == 0) url = Core.addTokenToUrl(url);
    return url;
};

Core.addTokenToUrl = function(url) {
    if (url.indexOf('/vault') == 0 && Core.contextRoot && Core.contextRoot.indexOf('/core') >= 0)
        url = Core.contextRoot.replace('/core', url);
    if (Core.apiToken)
        return url + '?api_token=' + Core.apiToken;
    else if (Core.accessToken)
        return url + '?access_token=' + Core.accessToken;
    else
        return url;
};

Core.setupRefresh = function(callback, interval) {
    if (!interval) interval = Core.LIST_UPDATE_INTERVAL;
    if (window.refreshInterval) clearInterval(window.refreshInterval);
    window.refreshInterval = setInterval(function() {
        if (!document.hidden) {
            console.log('refreshing', new Date());
            window.lastRefresh = new Date();
            if (callback) callback();
        } else
            console.log('not refreshing - hidden', new Date());
    }, interval);
    document.addEventListener('visibilitychange', function() {
        console.log('visibility change', document.hidden, new Date());
        if (!document.hidden && window.lastRefresh && new Date().getTime() - window.lastRefresh >= interval) {
            Core.setupRefresh(callback, interval);
            if (callback) callback();
        }
    });
},

//	Models

Core.ShareableModel = Backbone.Model.extend({

    editGroupPermission: null,

    isEditable: function() {
        return !this.get('deleted') && Core.client.me.id && (this.isNew() || Core.client.hasPermission('edit_all_data') || this.get('creatorId') == Core.client.me.id || (this.editGroupPermission && Core.client.hasGroupPermission(this.get('groupId'), this.editGroupPermission)));
    },

    isValidForGroup: function(groupId) {
        if (!this.get('groupId')) return !groupId;
        if (groupId && typeof groupId == 'object') groupId = groupId.id;
        if (this.get('groupId') == groupId) return true;
        var group = Core.client.groups.get(groupId);
        while (group) {
            if (group && group.get('parentGroupId') && this.get('groupId') == group.get('parentGroupId')) return true;
            group = Core.client.groups.get(group.get('parentGroupId'));
        };
        return false;
    },


});

Core.User = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/user',

    longName: function() {
        return Formatting.longName(this.attributes);
    },
    shortName: function() {
        return Formatting.shortName(this.attributes);
    },
    isSharingAllWithPrimaryGroup: function() {
        return this.get('primaryGroupId') && this.get('shareAllWithPrimaryGroup');
    },
    isEditable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        return this.isNew() || Core.client.hasPermission('edit_all_data') || this.id == Core.client.me.id || Core.client.hasGroupPermission(this.get('primaryGroupId'), 'admin_member_accounts');
    },
    isMemberOf: function(groupId) {
        if (typeof groupId == 'object') groupId = groupId.id;
        return _(this.get('groupMemberships')).findWhere({groupId: groupId});
    },
    isCustomerUserInGroup: function(groupId) {
        if (typeof groupId == 'object') groupId = groupId.id;
        var membership = _(this.get('groupMemberships')).findWhere({groupId: groupId});
        if (membership && membership.customerId) return true;
        return false;
    }
});

Core.Users = Backbone.Collection.extend({
    model: Core.User,
    url: function() {
        var path = '';
        var params = {};
        if (this.filterParams) {
            params = this.filterParams.params || {};
            if (typeof this.filterParams.path !== 'undefined') path = '/' + this.filterParams.path;
            if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
        }
        if (this.searchQuery) params.q = this.searchQuery;
        return Core.contextRoot + '/user' + path + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    },

    initialize: function(models, options) {
        if (options) {
            this.filterParams = options.filterParams;
        }
    },

    getByUserName: function (username) {
        return _(this.models).find(function (user) {
            return user.get('userName') == username;
        });
    },
});

Core.Permissions = Backbone.Model.extend({
    url: Core.contextRoot + '/user/me/permissions',

    parse: function (data) {
        return { permissions: data };
    }
});

Core.Preference = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/preference',
    idAttribute: 'path',

    initialize: function (options) {
        if (options.path) this.set({path: options.path});
    }
});

Core.Group = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/group',

    isEditable: function() { return Core.client.me.id && this.id && (this.get('creatorId') == Core.client.me.id || Core.client.hasGroupPermission(this.id, 'admin_members')); },

    configuration: function(config) {
        if (this.get('configuration')) return this.get('configuration')[config];
    },

    hasFeature: function(feature) {
        if (this.get('features')) return this.get('features').indexOf(feature) >= 0;
    },

    hasMember: function(userId) {
        return this.getMembership(userId) ? true : false;
    },

    getMembership: function(userId) {
        if (typeof userId == 'object') userId = userId.id;
        return this.get('members') && _(this.get('members')).findWhere({userId: userId});
    },

    memberHasPermission: function(userId, permission) {
        var membership = this.getMembership(userId);
        if (!membership) return false;
        if (membership.permissions && membership.permissions.indexOf(permission) >= 0) return true;
        var role = this.get('roles') && _(this.get('roles')).findWhere({id: membership.roleId});
        if (role && role.permissions && role.permissions.indexOf(permission) >= 0) return true;
        return false;
    },

    addRole: function(role) {
        var roles = this.get('roles');
        if (!role) {
            role = {};
            // copy default permissions from role with least amount of em
            var leastPermissions;
            _(roles).each(function (r) { if (!leastPermissions || r.permissions.length < leastPermissions.length) leastPermissions = r.permissions; });
            role.permissions = _(leastPermissions).clone();
        }
        roles.push(role);
        return role;
    },

    addMember: function(userId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/member/' + userId,
            type: 'POST'
        });
    },

    deleteMember: function(userId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/member/' + userId,
            type: 'DELETE'
        });
    },

    putMemberRole: function(userId, roleId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/member/' + userId + '/role/' + roleId,
            type: 'PUT'
        });
    },

    getOrGetInParentGroup: function(attribute) {
        var group = this;
        while (group) {
            var value = group.get(attribute);
            if (value) return value;
            group = Core.client.groups.get(group.get('parentGroupId'));
        }
        return null;
    },

    isAncestorOf: function(groupId) {
        do {
            var group = Core.client.groups.get(groupId);
            if (group != null) {
                if (group.get('parentGroupId') == this.id) return true;
                groupId = group.get('parentGroupId');
            } else
                groupId = null;
        } while (groupId);
    },

});

Core.Groups = Backbone.Collection.extend({
    model: Core.Group,
    url: function() {
        var path = '';
        var params = {};
        if (this.searchQuery) {
            path = '/search';
            params.q = this.searchQuery;
        }
        return Core.contextRoot + '/group' + path + '?' + $.param(params);
    },
    getGroupAndDescendants: function(groupId) {
        var ids = [];
        if (groupId == null) return ids;
        ids.push(groupId);

        var self = this;
        function addChildren(groupId) {
            self.each(function (group) {
                if (group.get('parentGroupId') == groupId) {
                    ids.push(group.id);
                    addChildren(group.id);
                }
            });
        }
        addChildren(groupId);

        return ids;
    },
    getGroupAndAncestors: function(groupId) {
        var ids = [];
        if (groupId == null) return ids;
        ids.push(groupId);
        this.each(function (group) {
            if (group.isAncestorOf(groupId)) ids.push(group.id);
        });
        return ids;
    }
});

Core.Activity = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/activity',
    editGroupPermission: 'admin_activities',
});

Core.Activities = Backbone.Collection.extend({
    model: Core.Activity,
    url: Core.contextRoot + '/activity'
});

Core.Category = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/category',
    editGroupPermission: 'admin_categories',
});

Core.Categories = Backbone.Collection.extend({
    model: Core.Category,
    url: Core.contextRoot + '/category'
});

Core.Customer = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/customer',
    editGroupPermission: 'admin_customers',

    defaults: function() { return {
        active: true,
        invoiced: true,
        chargedVat: true,
        type: 'BUSINESS',
        language: Core.language
    }; },

    setFromTemplate: function (template, previousTemplate) {
        if (!template) return;
        var attributes = $.extend(true, {}, template.attributes);
        delete attributes.id;
        delete attributes.uuid;
        delete attributes.lastModified;
        delete attributes.creatorId;
        delete attributes.creationTimeStamp;
        delete attributes.name;
        delete attributes.customerNo;
        delete attributes.projects;
        if ('groupId' in this.attributes) {
            delete attributes.groupId;
            delete attributes.groupUuid;
        }
        delete attributes.template;
        if (previousTemplate) {
            delete attributes.jobContact;
            delete attributes.location;
            delete attributes.invoiceContact;
            delete attributes.invoiceAddress;
            delete attributes.type;
            delete attributes.active;
            delete attributes.invoiced;
            if (this.get('ourReference') != previousTemplate.get('ourReference')) delete attributes.ourReference;
            if (this.get('ourReferenceUserId') != previousTemplate.get('ourReferenceUserId')) delete attributes.ourReferenceUserId;
            if (this.get('termsOfPaymentDays') != previousTemplate.get('termsOfPaymentDays')) delete attributes.termsOfPaymentDays;
            if (this.get('description') != previousTemplate.get('description')) delete attributes.description;
            if (this.get('locationDescription') != previousTemplate.get('locationDescription')) delete attributes.locationDescription;
        }
        this.set(attributes);
    },

    updateFromTemplate: function (template, previousTemplate) {
        // If editing existing customer, apply template custom fields
        var oldCustomFields = this.get('customFields');
        var newCustomFields = $.extend(true, [], template.get('customFields'));
        _(newCustomFields).each(function (field) {
            var oldField = _(oldCustomFields).findWhere({name: field.name});
            if (oldField) field.value = oldField.value;
        });
        _(oldCustomFields).each(function (oldField) {
            if (oldField.value && !_(newCustomFields).findWhere({name: oldField.name})) {
                newCustomFields.push(oldField);
            }
        });
        this.set({customFields: newCustomFields});
    },

    putProject: function(project) {
        if (!this.get('projects')) this.set('projects',[]);
        if (!project.id || !this.getProjectById(project.id))
            this.get('projects').push(project);
        return $.ajax({
            url: this.urlRoot + '/' + (this.get('uuid') || this.id) + '/project/' + project.uuid,
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(project)
        });
    },

    getProjectById: function(projectId) {
        return _(this.get('projects')).findWhere({id: projectId});
    },

    getProjectByUuid: function(projectUuid) {
        return _(this.get('projects')).findWhere({uuid: projectUuid});
    }
});

Core.Customers = Backbone.Collection.extend({
    model: Core.Customer,
    canPaginate: true,

    url: function() {
        var path = '';
        var params = {};
        if (this.filterParams && this.filterParams.path) path = this.filterParams.path;
        if (this.filterParams && this.filterParams.includeInactive) params.inactive = '';
        if (this.filterParams && this.filterParams.groups) params.groups = this.filterParams.groups;
        if (this.searchQuery) {
            params.q = this.searchQuery;
            params.inactive = 'true';
            params.limit = this.searchLimit || Core.SEARCH_RESULTS_LIMIT;
            path = 'search';
        }
        return Core.contextRoot + '/customer' + (path ? '/' + path : '') + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    },

    initialize: function(models, options) {
        this.filterParams = (options && options.filterParams) || {};
    },

    getProjectById: function(projectId) {
        for (var i = 0; i < this.models.length; i++) {
            var customer = this.models[i];
            var project = customer.getProjectById(projectId);
            if (project) return project;
        }
    },

    getCustomerByProjectId: function(projectId) {
        for (var i = 0; i < this.models.length; i++) {
            var customer = this.models[i];
            if (customer.getProjectById(projectId)) return customer;
        }
    },
});

Core.Invite = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/invite'
});

Core.Phrase = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/phrase',
    editGroupPermission: 'admin_phrases',
    defaults: function() {
        var phrase = {};
        if (Core.client.primaryGroup() && Core.client.hasGroupPermission(Core.client.primaryGroup(), 'admin_phrases'))
            phrase.groupId = Core.client.primaryGroup().id;
        return phrase;
    },
});

Core.Phrases = Backbone.Collection.extend({
    model: Core.Phrase,
    url: Core.contextRoot + '/phrase'
});

Core.Label = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/label',
    editGroupPermission: 'admin_labels',
    defaults: function() {
        var label = {
            internal: true,
            active: true
        };
        if (Core.client.primaryGroup() && Core.client.hasGroupPermission(Core.client.primaryGroup(), 'admin_labels'))
            label.groupId = Core.client.primaryGroup().id;
        return label;
    },
});

Core.Labels = Backbone.Collection.extend({
    model: Core.Label,
    url: Core.contextRoot + '/label'
});

Core.Message = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/message'
});

Core.Messages = Backbone.Collection.extend({
    model: Core.Message,
    url: function() {
        var path = '';
        var params = {};
        if (this.filterParams) {
            path = this.filterParams.path;
            if (this.filterParams.from) params.from = this.filterParams.from;
            if (this.filterParams.to) params.to = this.filterParams.to;
            if (this.filterParams.limit) params.limit = this.filterParams.limit;
            if (this.filterParams.offset) params.offset = this.filterParams.offset;
        }
        return Core.contextRoot + '/message' + (path ? '/' + path : '') + (Object.keys(params).length > 0 ? '?' + $.param(params) : '');
    }
});

Core.Todo = Backbone.Model.extend({
    url: Core.contextRoot + '/stats/me/todo'
});

Core.Place = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/place',
    editGroupPermission: 'admin_places',

    geocode: function() {
        var location = this.get('location') || {};
        var self = this;
        return $.getJSON(Core.contextRoot + '/map/search?' + $.param({q: location.placeName}), function (response) {
            if (response.places && response.places.length >= 1) {
                var place = response.places[0];
                console.log(location.placeName + ' => \n' + place.name + ' ' + place.latitude + ' ' + place.longitude + ' - ' + place.type + ' ' + place.source);
                location.latitude = place.latitude;
                location.longitude = place.longitude;
                self.geocodedPlace = place;
                if (place.type == 'STREET_ADDRESS' || place.type == 'PLACE')
                    self.geocodeResult = 'ok';
                else
                    self.geocodeResult = 'warn';
                self.set({location: location}, {silent: true});
            } else {
                console.log('geocoding failed', response, location.placeName);
                delete self.geocodedPlace;
                self.geocodeResult = 'fail';
            }
            delete self.pendingGeocode;
            self.trigger('geocodedone');
        });
    },

    hasValidLocation: function() {
        return this.get('location') && this.get('location').latitude && this.get('location').longitude ? true : false;
    },

});

Core.Places = Backbone.Collection.extend({
    model: Core.Place,
    url: Core.contextRoot + '/place'
});

Core.Article = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/article',
    editGroupPermission: 'admin_articles',

    defaults: function() {
        return {
            unit: 'ITEMS',
            invoiced: true,
            invoicedAmountSpecified: true,
            active: true,
            groupId: Core.client && Core.client.primaryGroup() && Core.client.primaryGroup().id,
        };
    },

    applyToItem: function(item, weight, distance) {
        if (this.id)
            item.articleId = this.id;
        else {
            delete item.articleId;
            item.articleUuid = this.get('uuid');
        }
        if (!item.description || item.description == item.articleDescription)
            item.description = this.get('description');
        else {
            item.description = item.description.replace(item.articleDescription, this.get('description'));
        }
        item.articleNo = this.get('articleNo');
        item.articleDescription = this.get('description');
        item.articleInvoiced = this.get('invoiced');
        item.articleUnitPrice = null;
        if (item.currency) {
            var group = Core.client.groups.get(this.get('groupId'));
            if (group && group.get('defaultCurrency') == item.currency)
                item.articleUnitPrice = this.get('unitPrice');
        } else
            item.articleUnitPrice = this.get('unitPrice');
        item.unit = this.get('unit');
        if (this.get('unit') == 'KG' && weight)
            item.amount = weight;
        else if (this.get('unit') == 'KM' && distance)
            item.amount = distance;
        if (item.unit == 'ITEMS' && (item.amount == null || typeof item.amount == 'undefined') && (item.invoicedAmount == null || typeof item.invoicedAmount == 'undefined'))
            item.amount = 1.0;
    },

    findUnitPrice: function(amount, currencyCode, weight, distance) {
        var group = Core.client.groups.get(this.get('groupId'));
        var prices = this.get('prices');
        if (prices) {
            // Find in same currency
            for (var i = prices.length-1; i >= 0; i--) {
                var price = prices[i];
                if (this.get('pricedByWeight') && weight && price.minWeight > weight + 1e-5) continue;
                if (this.get('pricedByDistance') && distance && price.minDistance > distance + 1e-5) continue;
                if (amount >= price.minAmount - 1e-5 && (currencyCode == price.currency || (!currencyCode && price.currency == group.get('defaultCurrency'))))
                    return price.unitPrice;
            }
            // Find in prices by translating currency
            if (group) {
                var currencies = group.get('currencies');
                var currency = _(currencies).findWhere({code: currencyCode});
                if (currency && currency.buyRate) {
                    for (var i = prices.length - 1; i >= 0; i--) {
                        var price = prices[i];
                        if (this.get('pricedByWeight') && weight && price.minWeight > weight + 1e-5) continue;
                        if (this.get('pricedByDistance') && distance && price.minDistance > distance + 1e-5) continue;
                        if (amount >= price.minAmount - 1e-5 && (!price.currency || price.currency == group.get('defaultCurrency')))
                            return Math.round(price.unitPrice / currency.buyRate);
                    }
                }
            }
        }
        // Find by default price same currency
        if (!currencyCode || !group || currencyCode == group.get('defaultCurrency'))
            return this.get('unitPrice');
        // Find by default price translating currency
        if (this.get('unitPrice') && group && group.get('defaultCurrency')) {
            var currency = _(group.get('currencies')).findWhere({code: currencyCode});
            if (currency && currency.buyRate) {
                return Math.round(this.get('unitPrice') / currency.buyRate);
            }
        }
    }


});

Core.Articles = Backbone.Collection.extend({
    model: Core.Article,
    canPaginate: true,
    url: function() {
        if (this.customUrl)
            return this.customUrl;
        var url;
        var params = { };
        if (this.filterParams.includeInactive) params.inactive = '';
        if (this.filterParams.groups) params.groups = this.filterParams.groups;
        if (this.searchQuery) {
            url = Core.contextRoot + '/article/search';
            params.q = this.searchQuery;
            params.inactive = 'true';
            params.limit = this.searchLimit || Core.SEARCH_RESULTS_LIMIT;
        } else {
            if (this.filterParams) {
                if (this.filterParams.group) params.group = this.filterParams.group;
                if (this.filterParams.limit) params.limit = this.filterParams.limit;
                if (this.filterParams.offset) params.offset = this.filterParams.offset;
                if (this.filterParams.modified) params.modified = this.filterParams.modified;
            }
            url = Core.contextRoot + '/article' + (this.filterParams.path ? '/'+this.filterParams.path : '');
        }
        var paramsStr = $.param(params);
        if (paramsStr.length > 0) url += '?' + paramsStr;
        return url;
    },
    initialize: function(options) {
        this.filterParams = {};
        if (options && options.url)
            this.customUrl = options.url;
        else if (options && options.filterParams)
            _(this.filterParams).extend(options.filterParams);
    }
});

Core.PriceList = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/price/list',
    editGroupPermission: 'pricing',
});

Core.PriceLists = Backbone.Collection.extend({
    model: Core.PriceList,
    url: Core.contextRoot + '/price/list',
});

Core.Event = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/event'
});

Core.Events = Backbone.Collection.extend({
    model: Core.Event,

    url: function() {
        if (!this.filterParams) this.filterParams = {};
        var path = Core.Event.prototype.urlRoot;
        if (this.filterParams.path) path += '/' + this.filterParams.path;
        return path + '?' + $.param(this.filterParams.params);
    },

    initialize: function(options) {
        this.filterParams = { params: { limit: 10 }};
        if (options && options.filterParams)
            _(this.filterParams).extend(options.filterParams);
    },
});

Core.Announcement = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/announcement'
});

Core.Announcements = Backbone.Collection.extend({
    model: Core.Announcement,
    url: Core.contextRoot + '/announcement',
});

Core.AvailabilityEntry = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/availability'
});

Core.Availability = Backbone.Collection.extend({
    model: Core.AvailabilityEntry,

    url: function() {
        var params = {
            from: this.from,
            group: this.group
        };
        if (this.to) params.to = this.to;
        return Core.contextRoot + '/' + this.type + '/availability?' + $.param(params);
    },

    initialize: function(models, options) {
        this.type = options.type;
        this.from = options.from;
        this.to = options.to;
        this.group = options.group;
    },
});

//	Client

Core.setupTokens = function(apiToken, accessToken) {
    if (!apiToken && typeof CoreClient != 'undefined' && CoreClient.apiToken)
        apiToken = CoreClient.apiToken;
    /*else if (!apiToken)
        apiToken = $.url().param('api_token');
    if (!accessToken)
        accessToken = $.url().param('access_token');*/

    try {
        if (sessionStorage.core_apiToken && sessionStorage.core_apiToken != apiToken)
            sessionStorage.clear();
        if (apiToken)
            sessionStorage.core_apiToken = apiToken;
    } catch (e) {} // SecurityError

    Core.apiToken = apiToken;
    Core.accessToken = accessToken;

    /*if ($.url().param('api_key'))
        Core.apiKey = $.url().param('api_key');*/
    axios.defaults.headers.common['API-Version'] = Core.apiVersion;
    if (Core.apiKey)
        axios.defaults.headers.common['API-Key'] = Core.apiKey;
    if (apiToken)
        axios.defaults.headers.common['API-Token'] = apiToken;
    if (accessToken)
        axios.defaults.headers.common['X-Access-Token'] = accessToken;
    if (Core.language)
        axios.defaults.headers.common['Accept-Language'] = Core.language;
    /*$(document).ajaxSend(function(e, xhr, options) {
        xhr.setRequestHeader('API-Version', Core.apiVersion);
        if (Core.apiKey)
            xhr.setRequestHeader('API-Key', Core.apiKey);
        if (apiToken)
            xhr.setRequestHeader('API-Token', apiToken);
        if (accessToken)
            xhr.setRequestHeader('X-Access-Token', accessToken);
        if (Core.language)
            xhr.setRequestHeader('Accept-Language', Core.language);
    });*/
};

Core.Client = function() {
    this.me = new Core.User();
    this.me.url = this.me.urlRoot + '/me';
    this.permissions = new Core.Permissions();
    if (Core.preload) {
        this.me.set(this.me.parse(Core.preload.me));
        this.permissions.set(this.permissions.parse(Core.preload.permissions));
    } else if (Core.apiToken) {
        this.me.setCacheOptions({key: 'core_me'});
        this.permissions.setCacheOptions({key: 'core_permissions'});
        this.me.getCachedOrFetch();
        this.permissions.getCachedOrFetch();
    }

    this.users = new Core.Users();
    this.users.setCacheOptions({key: 'core_users', ttl: 500});
    this.groups = new Core.Groups();
    this.groups.setCacheOptions({key: 'core_groups', ttl: 3600});
    this.activities = new Core.Activities();
    this.activities.setCacheOptions({key: 'core_activities', ttl: 3600});
    this.categories = new Core.Categories();
    this.categories.setCacheOptions({key: 'core_categories', ttl: 3600});
    this.places = new Core.Places();
    this.places.setCacheOptions({key: 'core_places', ttl: 3600});
    this.customers = new Core.Customers();
    this.customers.setCacheOptions({key: 'core_customers', ttl: 3600});
    this.phrases = new Core.Phrases();
    this.phrases.setCacheOptions({key: 'core_phrases', ttl: 3600});
    this.labels = new Core.Labels();
    this.labels.setCacheOptions({key: 'core_labels', ttl: 3600});
    this.articles = new Core.Articles();
    this.articles.filterParams.path = 'selectable';
    this.articles.setCacheOptions({key: 'core_articles', ttl: 3600});
    this.priceLists = new Core.PriceLists();
    this.priceLists.setCacheOptions({key: 'core_priceLists', ttl: 3600});
    if (Core.AssetTypes) {
        this.assetTypes = new Core.AssetTypes([], {filterParams: {path: 'list'}});
        this.assetTypes.setCacheOptions({key: 'core_assetTypes', ttl: 3600});
    }
    if (Core.Assets) {
        this.assets = new Core.Assets();
        this.assets.setCacheOptions({key: 'core_assets', ttl: 3600});
    }
    if (Core.Vehicles) {
        this.vehicles = new Core.Vehicles();
        this.vehicles.setCacheOptions({key: 'core_vehicles', ttl: 3600});
    }

    if (window.localStorage) {
        if (localStorage.me_id && localStorage.me_id != this.me.id) localStorage.clear();
        if (this.me.id) localStorage.me_id = this.me.id;
    }
};

Core.Client.prototype.initialize = function() {
    if (Core.apiToken) {
        this.groups.getCachedOrFetch();
    }
};

Core.Client.prototype.hasFeature = function(feature) {
    var features = this.me.get('features');
    return features && features.indexOf(feature) >= 0;
};

Core.Client.prototype.hasPermission = function(permission) {
    var permissions = this.permissions.get('permissions');
    return typeof permissions != 'undefined' && permissions.indexOf(permission) >= 0;
};

Core.Client.prototype.hasGroupPermission = function(groupId, permission) {
    if (groupId && typeof groupId == 'object' && groupId.id) groupId = groupId.id;
    if (Array.isArray(permission)) {
        var hasAll = true;
        _(permission).each(function(p) { if (!this.hasGroupPermission(groupId, p)) hasAll = false; }, this);
        return hasAll;
    }
    if (this.me && this.me.get('groupMemberships')) {
        var membership = _(this.me.get('groupMemberships')).findWhere({groupId: parseInt(groupId)});
        if (!membership) membership = _(this.me.get('groupMemberships')).findWhere({groupUuid: groupId});
        if (membership && membership.permissions) return membership.permissions.indexOf(permission) >= 0;
    }
    return false;
};

Core.Client.prototype.hasPermissionInAnyGroup = function(permission) {
    var foundIt = false;
    if (this.me && this.me.get('groupMemberships')) {
        _(this.me.get('groupMemberships')).each(function (membership) {
            if (membership && membership.permissions && membership.permissions.indexOf(permission) >= 0) foundIt = true;
        }, this);
    }
    return foundIt;
};

Core.Client.prototype.hasPermissionInAnyOfGroups = function(permission, groups) {
    for (var i = 0; i < groups.length; i++) {
        if (this.hasGroupPermission(groups[i], permission)) return true;
    }
    return false;
};

Core.Client.prototype.hasPermissionInGroupOrDescendant = function(groupId, permission) {
    if (!groupId || !permission) return false;
    var groupIds = this.groups.getGroupAndDescendants(groupId);
    for (var i = 0; i < groupIds.length; i++) {
        if (this.hasGroupPermission(groupIds[i], permission)) return true;
    }
    return false;
};

Core.Client.prototype.groupIdsWithPermission = function(permission) {
    var groupIds = [];
    if (this.me && this.me.get('groupMemberships')) {
        _(this.me.get('groupMemberships')).each(function (membership) {
            if (this.hasGroupPermission(membership.groupId, permission)) groupIds.push(parseInt(membership.groupId));
        }, this);
    }
    return groupIds;
};

Core.Client.prototype.groupHasConfiguration = function(groupId, configuration) {
    var group = this.groups.get(groupId);
    if (!group) return;
    return configuration in group.get('configuration');
};

Core.Client.prototype.groupHasFeature = function(groupId, feature) {
    var group = this.groups.get(groupId);
    if (!group) return;
    return feature && group.get('features') && group.get('features').indexOf(feature) >= 0;
};

Core.Client.prototype.primaryGroup = function() {
    return this.groups.get(this.me.get('primaryGroupId'));
};

Core.Client.prototype.primaryGroupHasConfiguration = function(configuration) {
    return this.primaryGroup() && this.groupHasConfiguration(this.primaryGroup(), configuration);
};

Core.Client.prototype.primaryGroupConfiguration = function(configuration) {
    if (this.primaryGroup() && this.primaryGroup().get('configuration'))
        return this.primaryGroup().get('configuration')[configuration];
};

Core.Client.prototype.userHasGroupPermission = function(userId, groupId, permission) {
    if (userId && typeof userId == 'object') userId = userId.id;
    if (groupId && typeof groupId == 'object') groupId = groupId.id;
    var group = this.groups.get(groupId);
    if (!group) return undefined;
    var membership = _(group.get('members')).findWhere({userId: userId});
    if (!membership) return false;
    if (membership.permissions) return membership.permissions.indexOf(permission) >= 0;
    var role = _(group.get('roles')).findWhere({id: membership.roleId});
    if (!role) return false;
    if (role.permissions) return role.permissions.indexOf(permission) >= 0;
};

Core.Client.prototype.userHasPermissionInAnyGroup = function(userId, permission) {
    if (!this.groups || this.groups.length == 0) return undefined;
    for (var i = 0; i < this.groups.length; i++) {
        if (this.userHasGroupPermission(userId, this.groups.at(i).id, permission)) return true;
    }
    return false;
};

Core.Client.prototype.fetchArticlesByNo = function(articleNo, groupId, options) {
    var filterParams = {path: 'no/' + articleNo };
    if (groupId) filterParams.group = groupId;
    var articles = new Core.Articles({filterParams: filterParams});
    return articles.fetch(options);
};

Core.Client.prototype.searchArticles = function(query, limit) {
    var articles = new Core.Articles();
    articles.searchQuery = query;
    articles.searchLimit = limit;
    return articles.fetch();
};

Core.Client.prototype.fetchItemPrices = function(items, customerId, projectId, params) {
    var url = Core.contextRoot + '/price/';
    if (customerId)
        url += 'customer/' + customerId + '/';
    if (projectId)
        url += 'project/' + projectId + '/';
    url += 'items' + (params ? '?' + $.param(params) : '');
    return $.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify(items),
        contentType: 'application/json'
    });
};

Core.Client.prototype.addExtensionServiceToUser = function(service, user, exclusive) {
    return $.ajax({
        url: Core.contextRoot + '/user/' + (user ? user : 'me') + '/serviceaccount/' + service + (exclusive ? '?exclusive' : ''),
        type: 'POST'
    });
};

Core.Client.prototype.deleteExtensionServiceToUser = function(service, user) {
    return $.ajax({
        url: Core.contextRoot + '/user/' + (user ? user : 'me') + '/serviceaccount/' + service,
        type: 'DELETE'
    });
};
