/**
 * Generic session/local storage cache for Backbone.
 *
 * Example use:
 *
 * MyModel = Backbone.Model.extend({urlRoot:'/mymodel'});
 * MyModel.setCacheOptions({ key: 'mymodel', store: 'local', ttl: 120});
 *
 * store is 'local' or 'session' (default)
 * ttl is in minutes
 *
 */

(function () {

    "use strict";

    var DEFAULT_TTL = 60;

    var getCachedOrFetch = function(key, store, ttl, callback, fetch, delay) {
        if (typeof store == 'undefined')
            store = 'session';
        if (typeof ttl == 'undefined')
            ttl = DEFAULT_TTL;
        var storage = (store == 'local' ? window.localStorage : window.sessionStorage);
        var expire = storage[key+'_expires'];

        if (typeof storage[key] != 'undefined' && new Date().getTime() < expire) {
            if (callback) callback(storage[key], expire);
            return true;
        } else {
            if (typeof storage[key] != 'undefined') {
                // get cached first, then fetch
                if (callback) callback(storage[key], expire);
            }
            var fetchCallback = function(data) {
                storage[key] = data;
                storage[key + '_expires'] = new Date().getTime() + ttl*1000;
            };
            if (delay)
                self.fetchTimeout = setTimeout(function() {
                    delete self.fetchTimeout;
                    fetch(fetchCallback);
                }, delay);
            else
                fetch(fetchCallback);
            return false;
        }
    };


    // Model

    Backbone.Model.prototype._fetch = Backbone.Model.prototype.fetch;
    Backbone.Model.prototype.fetch = function(options) {
        this.loading = true;
        var self = this;
        var start = new Date().getTime();
        return this._fetch(options).complete(function () {
            self.loading = false;
            self.loaded = true;
            self.loadTime = new Date().getTime() - start;
            self.trigger('load', self);
        });
    };

    Backbone.Model.prototype.setCacheOptions = function(options) {
        this.cacheOptions = options;
        return this;
    };

    Backbone.Model.prototype.getCached = function(ignoreExpired) {
        if (this.cacheOptions && this.cacheOptions.key) {
            var key = this.cacheOptions.key;
            var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
            var expire = storage[key+'_expires'];
            var expired = !(new Date().getTime() < expire);
            if (typeof storage[key] != 'undefined') {
                if (!expired || ignoreExpired) {
                    this.loaded = true;
                    this.set(JSON.parse(storage[key]));
                    return true;
                }
            }
        }
        return false;
    };

    Backbone.Model.prototype.delayedFetchAndCache = function(delay, options) {
        var self = this;
        self.fetchTimeout = setTimeout(function() {
            delete self.fetchTimeout;
            self.fetchAndCache(options);
        }, delay);
    };

    Backbone.Model.prototype.fetchAndCache = function(options) {
        if (!this.cacheOptions)
            return this.fetch(options);
        var key = this.cacheOptions.key;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        var ttl = this.cacheOptions.ttl || DEFAULT_TTL;
        return this.fetch(options).success(function (data) {
            storage[key] = JSON.stringify(data);
            storage[key + '_expires'] = new Date().getTime() + ttl*1000;
        });
    };

    Backbone.Model.prototype.getCachedOrFetch = function(callback, scope, delay)  {
        if (typeof callback == 'number' && !scope && !delay) {
            delay = callback;
            callback = undefined;
        }
        var self = this;
        return getCachedOrFetch(this.cacheOptions.key, this.cacheOptions.store, this.cacheOptions.ttl, function (data) {
                self.clear({silent: true});
                self.loaded = true;
                self.set(JSON.parse(data));
                if (callback) callback.apply(scope, [self, true]);
            },
            function(storecallback) {
                if (self.loading) return;
                var options = {
                    success: function(model) {
                        storecallback(JSON.stringify(model.toJSON()));
                        if (callback) callback.apply(scope, [model, false]);
                        model.trigger('change');
                    },
                };
                return self.fetch(options);
            }, delay);
    };

    Backbone.Model.prototype.updateCache = function() {
        if (!this.cacheOptions) return this;
        var key = this.cacheOptions.key;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        var ttl = this.cacheOptions.ttl || DEFAULT_TTL;
        storage[key] = JSON.stringify(this.toJSON());
        storage[key + '_expires'] = new Date().getTime() + ttl*1000;
        return this;
    };

    Backbone.Model.prototype.clearCache = function() {
        if (!this.cacheOptions) return this;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        storage.removeItem(this.cacheOptions.key);
        storage.removeItem(this.cacheOptions.key + '_expires');
        return this;
    };

    Backbone.Model.prototype.expireCache = function() {
        if (!this.cacheOptions) return this;
        var key = this.cacheOptions.key;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        storage[key + '_expires'] = new Date().getTime()-1;
        return this;
    };


    // Collection

    Backbone.Collection.prototype._fetch = Backbone.Collection.prototype.fetch;
    Backbone.Collection.prototype.fetch = function(options) {
        this.loading = true;
        var self = this;
        var start = new Date().getTime();
        return this._fetch(options).complete(function (data) {
            self.loading = false;
            self.loaded = true;
            self.each(function (model) { model.loaded = true; });
            self.loadTime = new Date().getTime() - start;
            self.trigger('load', self);
        });
    };

    Backbone.Collection.prototype.setCacheOptions = Backbone.Model.prototype.setCacheOptions;

    Backbone.Collection.prototype.getCached = function(ignoreExpired) {
        if (this.cacheOptions && this.cacheOptions.key) {
            var key = this.cacheOptions.key;
            var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
            var expire = storage[key+'_expires'];
            var expired = !(new Date().getTime() < expire);
            if (typeof storage[key] != 'undefined') {
                if (!expired || ignoreExpired) {
                    this.loaded = true;
                    this.each(function (model) { model.loaded = true; });
                    this.reset(JSON.parse(storage[key]));
                    return true;
                }
            }
        }
        return false;
    };

    Backbone.Collection.prototype.delayedFetchAndCache = Backbone.Model.prototype.delayedFetchAndCache;

    Backbone.Collection.prototype.fetchAndCache = function(options) {
        if (!this.cacheOptions)
            return this.fetch(_({reset: true, cache: false}).extend(options));
        var key = this.cacheOptions.key;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        var ttl = this.cacheOptions.ttl || DEFAULT_TTL;
        return this.fetch(_({reset: true, cache: false}).extend(options)).success(function (data) {
            if (data.length > Backbone.Collection.PAGINATION_THRESHOLD)
                console.error('Warning: fetchAndCache() over pagination threshold ' + key);
            storage[key] = JSON.stringify(data);
            storage[key + '_expires'] = new Date().getTime() + ttl*1000;
        });
    }

    Backbone.Collection.prototype.getCachedOrFetch = function(callback, scope, delay) {
        if (typeof callback == 'number' && !scope && !delay) {
            delay = callback;
            callback = undefined;
        }
        if (!this.cacheOptions) {
            console.error('Cache options not set for ' + this, this);
            return;
        }
        var self = this;
        getCachedOrFetch(this.cacheOptions.key, this.cacheOptions.store, this.cacheOptions.ttl, function (data) {
                self.loaded = true;
                self.each(function (model) { model.loaded = true; });
                self.reset(JSON.parse(data));
                if (callback) callback.apply(scope, [self, true]);
            },
            function(storecallback) {
                if (self.loading) return;
                var options = {
                    success: function(collection) {
                        if (collection.length > Backbone.Collection.PAGINATION_THRESHOLD)
                            console.error('Warning: getCachedOrFetch() over pagination threshold ' + self.cacheOptions.key);
                        storecallback(JSON.stringify(collection.toJSON()));
                        if (callback) callback.apply(scope, [collection, false]);
                        collection.trigger('reset');
                    }
                };
                return self.fetch(_({reset: true, cache: false}).extend(options));
            }, delay);
    };

    Backbone.Collection.prototype.updateCache = Backbone.Model.prototype.updateCache;

    Backbone.Collection.prototype.clearCache = Backbone.Model.prototype.clearCache;

    Backbone.Collection.prototype.expireCache = Backbone.Model.prototype.expireCache;

    Backbone.Collection.prototype.fetchUpdates = function(options) {
        if (this.length == 0) return this.fetchAndCache();
        var maxLastModified = _(this.pluck('lastModified')).max();
        if (!isFinite(maxLastModified)) return this.fetchAndCache();
        var url = this.url();
        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'modified=' + maxLastModified;
        if (!this.cacheOptions)
            return this.fetch(_({remove: false, url: url}).extend(options));
        var key = this.cacheOptions.key;
        var storage = (this.cacheOptions.store == 'local' ? window.localStorage : window.sessionStorage);
        var ttl = this.cacheOptions.ttl || DEFAULT_TTL;
        var self = this;
        return this.fetch(_({remove: false, url: url}).extend(options)).success(function (data) {
            storage[key] = JSON.stringify(self.toJSON());
            storage[key + '_expires'] = new Date().getTime() + ttl*1000;
        });
    };

} ());
