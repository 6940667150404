"use strict";

var WorkReport = Core.ShareableModel.extend({
	
	urlRoot: Core.contextRoot + '/workreport',

    defaults: function() {
        var today = new Date();
        today.setHours(0,0,0,0);
        return {
            workDate: today,
            documents: [],
            labels: [],
            userId: Core.client && Core.client.me ? Core.client.me.id : null,
            finished: true
        };
    },

    setFromTemplate: function (template, options) {
        var attributes = $.extend(true, {}, template.attributes);
        attributes.fromTemplateId = template.attributes.id;
        delete attributes.title;
        delete attributes.id;
        delete attributes.uuid;
        delete attributes.workDate;
        delete attributes.userId;
        delete attributes.lastModified;
        delete attributes.creatorId;
        delete attributes.creationTimeStamp;
        delete attributes.template;
        delete attributes.jobId;
        delete attributes.jobUuid;
        delete attributes.jobTitle;
        delete attributes.jobNo;
        delete attributes.jobResourceId;
        delete attributes.jobState;
        delete attributes.invoiceId;
        delete attributes.invoicedTimeStamp;
        delete attributes.invoicedById;
        delete attributes.totalInvoicedPrice;
        delete attributes.pendingInvoicingPrice;
        delete attributes.pendingInvoicingPriceComplete;

        if (attributes.items)
            _(attributes.items).each(function (item) { delete item.id; delete item.uuid; });

        var current = this.attributes;
        // Don't change group if we don't have permission in that group
        if (!Core.client.hasGroupPermission(attributes.groupId, 'do_work')) delete attributes.groupId;
        // Retain current group if template is from parent group
        if (template.isValidForGroup(current.groupId)) delete attributes.groupId;

        if (!this.savedCustomFields) this.savedCustomFields = [];
        _(this.get('customFields')).each(function (field) {
            var existingField = _(this.savedCustomFields).findWhere({name: field.name});
            if (existingField)
                existingField.value = field.value;
            else
                this.savedCustomFields.push(_(field).clone());
        }, this);
        if (attributes.customFields && current.customFields) {
            _(current.customFields).each(function (prefield) {
                var postfield = _(attributes.customFields).findWhere({name: prefield.name});
                if (!postfield) postfield = _(attributes.customFields).findWhere({label: prefield.label});
                if (postfield) postfield.value = prefield.value;
            }, this);
        }
        _(this.savedCustomFields).each(function (field) {
            if (!field.value) return;
            var postfield = _(attributes.customFields).findWhere({name: field.name});
            if (!postfield) postfield = _(attributes.customFields).findWhere({label: field.label});
            if (postfield && !postfield.value) postfield.value = field.value;
        }, this);

        this.set(attributes, options);
    },

    zeroInvoicedAmounts: function() {
        var items = this.get('items');
        _(items).each(function (item) { item.invoicedAmount = 0; });
        this.set({
            invoicedWorkDuration: 0,
            items: items
        });
    },

    isEditable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        if (this.get('jobState') == 'CLOSED') return false;
        if (this.get('attestedTimeStamp')) return false;
        if (this.get('invoicedTimeStamp')) return false;
        if (Core.client.hasGroupPermission(this.get('groupId'), 'alter_work_reports')) return true;
        if (!this.isNew() && this.get('finished') && this.get('groupId') && Core.client.groupHasConfiguration(this.get('groupId'), 'lock_finished_reports')) return false;
        if (this.isNew() || this.get('creatorId') == Core.client.me.id || this.get('userId') == Core.client.me.id) return true;
    },

    isAttestable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        if (this.get('attestedTimeStamp')) return false;
        var group = Core.client.groups.get(this.get('groupId'));
        if (group && group.get('features') && group.get('features').indexOf('attest') < 0) return false;
        if (Core.client.hasGroupPermission(this.get('groupId'), 'attest_work_reports')) return true;
    },

    isPublishable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        if (this.get('attestedTimeStamp') && Core.client.hasGroupPermission(this.get('groupId'), 'attest_work_reports')) return true;
        if (this.get('invoicedTimeStamp') && Core.client.hasGroupPermission(this.get('groupId'), 'invoicing')) return true;
    },

    isDeletable: function() {
        if (!Core.client.me.id) return false;
        if (this.get('deleted')) return false;
        if (this.get('jobState') == 'CLOSED') return false;
        if (this.get('invoicedTimeStamp') && this.get('invoiceId')) return false;
        if (Core.client.hasGroupPermission(this.get('groupId'), 'alter_work_reports')) return true;
        if (!this.isNew() && this.get('finished') && this.get('groupId') && Core.client.groupHasConfiguration(this.get('groupId'), 'lock_finished_reports')) return false;
        if (this.isNew() || this.get('creatorId') == Core.client.me.id || this.get('userId') == Core.client.me.id) return true;
    },

    isToBeInvoiced: function() {
        if (this.get('type') == 'ABSENCE') return false;
        if (this.get('customerId')) {
            var customer = Core.client.customers.get(this.get('customerId'));
            if (!customer)
                return true;
            if (!customer.get('invoiced'))
                return false;
            if (this.get('customerProjectId')) {
                var project = customer.getProjectById(this.get('customerProjectId'));
                if (project && !project.invoiced)
                    return false;
            }
            return true;
        }
        return true;
    },

    getTotalHours: function(includeAddOn) {
        if (this.get('items') && this.get('items').length > 0) {
            return this.sumAmountsByUnit('HOURS', includeAddOn, true);
        } else {
            return this.get('workDuration') ? this.get('workDuration')/60 : 0;
        }
    },

    getTotalInvoicedHours: function(includeAddOn) {
        if (this.get('type') == 'ABSENCE') return 0;
        if (this.get('items') && this.get('items').length > 0) {
            return this.sumInvoicedAmountsByUnit('HOURS', includeAddOn, true);
        } else {
            if (this.get('invoicedWorkDuration') == null)
                return this.getTotalHours();
            else
                return this.get('invoicedWorkDuration') ? this.get('invoicedWorkDuration')/60 : 0;
        }
    },

    sumAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var sum = 0;
        if (this.get('items') && this.get('items').length > 0) {
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (item.delivered === false) return;
                if (item.addOn && !includeAddOn) return;
                if (!item.workHours && requireWorkHours) return;
                if (!unit || (item.unit == unit && item.amount))
                    sum += item.amount;
            }, this);
        }
        return sum;
    },

    sumInvoicedAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var sum = 0;
        if (this.get('items') && this.get('items').length > 0) {
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (item.articleInvoiced === false) return;
                if (item.delivered === false) return;
                if (item.addOn && !includeAddOn) return;
                if (!item.workHours && requireWorkHours) return;
                if (!unit || (item.unit == unit)) {
                    if (item.invoicedAmount != null)
                        sum += item.invoicedAmount;
                    else if (item.amount)
                        sum += item.amount;
                }
            }, this);
        }
        return sum;
    },

    sumAmountsByArticle: function(articleId) {
        var sum = 0;
        if (this.get('items') && this.get('items').length > 0) {
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (item.articleId != articleId) return;
                if (item.amount) sum += item.amount;
            }, this);
        }
        return sum;
    },

    hasSpecificInvoicedAmount: function(unit) {
        if ((!unit || unit == 'HOURS') && typeof this.get('invoicedWorkDuration') != 'undefined' && this.get('invoicedWorkDuration') != null && this.get('invoicedWorkDuration') != this.get('workDuration'))
            return true;
        var items = this.get('items');
        if (items) {
            for (var i = 0; i < this.get('items').length; i++) {
                var item = items[i];
                if (!item) continue;
                if ((!unit || unit == item.unit) && typeof item.invoicedAmount != 'undefined' && item.invoicedAmount != null && item.invoicedAmount != item.amount)
                    return true;
            }
        }
        return false;
    },

    hasOnlyInvoicedAmount: function(unit) {
        if ((!unit || unit == 'HOURS') && typeof this.get('workDuration') != 'undefined' && this.get('workDuration') != null)
            return false;
        var items = this.get('items');
        if (items) {
            for (var i = 0; i < this.get('items').length; i++) {
                var item = items[i];
                if (!item) continue;
                if ((!unit || unit == item) && typeof item.amount != 'undefined' && item.amount != null)
                    return false;
            }
        }
        return true;
    },

    hasAnyAmount: function(unit) {
        if ((!unit || unit == 'HOURS') && ((typeof this.get('workDuration') != 'undefined' && this.get('workDuration') != null)
            || (typeof this.get('invoicedWorkDuration') != 'undefined' && this.get('invoicedWorkDuration') != null)))
            return true;
        var items = this.get('items');
        if (items) {
            for (var i = 0; i < this.get('items').length; i++) {
                var item = items[i];
                if (!item) continue;
                if ((!unit || unit == item) && ((typeof item.amount != 'undefined' && item.amount != null) || (typeof item.invoicedAmount != 'undefined' && item.invoicedAmount != null)))
                    return true;
            }
        }
        return false;
    },

    sumInvoicedPrice: function(unit, onlyInvoiced, onlyWorkHours) {
        if (this.get('items') && this.get('items').length > 0) {
            var sum = 0;
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (onlyInvoiced && !item.invoiceId) return;
                if (onlyWorkHours && !item.workHours) return;
                if (item.articleInvoiced === false) return;
                if (item.delivered === false) return;
                if (!unit || item.unit == unit) {
                    if (item.unit == 'MONEY') {
                        if (item.invoicedAmount != null)
                            sum += 100*item.invoicedAmount;
                        else
                            sum += 100*item.amount;
                    } else {
                        var unitPrice = item.unitPrice;
                        if (item.unitPrice == null) unitPrice = item.articleUnitPrice;
                        if (unitPrice) {
                            if (item.invoicedAmount != null)
                                sum += unitPrice*item.invoicedAmount;
                            else if (item.amount)
                                sum += unitPrice*item.amount;
                        }
                    }
                }
            }, this);
            return parseInt(Math.round(sum));
        } else if (!unit || unit == 'HOURS') {
            if (onlyInvoiced && !this.get('invoiceId')) return 0;
            var hours = this.getTotalInvoicedHours();
            if (!hours)
                return hours;
            else {
                var hourlyRate = this.get('hourlyRate');
                return hourlyRate != null ? parseInt(Math.round(hours*hourlyRate)) : 0;
            }
        } else
            return 0;
    },

    sumAmountsToInvoice: function(unit) {
        if (this.get('items') && this.get('items').length > 0) {
            var amount = 0;
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (item.invoiceId) return;
                if (item.articleInvoiced === false) return;
                if (item.delivered === false) return;
                if (!unit || item.unit == unit) {
                    if (item.invoicedAmount != null)
                        amount += item.invoicedAmount;
                    else if (item.amount)
                        amount += item.amount;
                }
            }, this);
            return amount;
        } else if (!unit || unit == 'HOURS') {
            return this.get('invoicedTimeStamp') ? 0 : this.getTotalInvoicedHours();
        } else
            return 0;
    },

    isPriced: function(unit) {
        var items = this.get('items');
        if (items && items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (!item) continue;
                if ((!unit || unit == item.unit) && item.unit != 'MONEY' && (item.amount || item.invoicedAmount) && item.invoicedAmount != 0
                    && (typeof item.unitPrice == 'undefined' || item.unitPrice == null) && (typeof item.articleUnitPrice == 'undefined' || item.articleUnitPrice == null)
                    && item.articleInvoiced !== false && item.delivered !== false)
                    return false;
            }
            return true;
        } else if (!unit || unit == 'HOURS') {
            var hourlyRate = this.get('hourlyRate');
            return (!this.get('workDuration') && !this.get('invoicedWorkDuration')) || (typeof hourlyRate != 'undefined' && hourlyRate != null);
        } else
            return true;
    },

    itemsWithArticle: function(articleId) {
        if (articleId && typeof articleId != 'number')
            articleId = articleId.id;
        return _(this.get('items')).where({articleId: articleId});
    },

    fetchItemPrices: function(options) {
        var self = this;
        return Core.client.fetchItemPrices(this.get('items'), this.get('customerId'), this.get('customerProjectId')).success(function(items) {
            self.set({items: items}, options)
        });
    },

    itemsWithUnit: function(unit) {
        return _(this.get('items')).where({unit: unit});
    },

    isInvoiced: function() { return this.get('invoicedTimeStamp'); },

    titleExtended: function() {
        if (this.get('title')) return this.get('title');
        else if (this.get('jobTitle')) return this.get('jobTitle');
        else if (this.get('assetName')) {
            var title = (this.get('assetNo') ? this.get('assetNo') + ' - ' : '') + this.get('assetName');
            if (this.get('activityName')) title = this.get('activityName') + ': ' + title;
            return title;
        } else if (this.get('customerName')) {
            var title = this.get('customerName');
            if (this.get('activityName')) title = this.get('activityName') + ': ' + title;
            if (this.get('customerProjectNo') || this.get('customerProjectName')) title += ':';
            if (this.get('customerProjectNo')) title += ' ' + this.get('customerProjectNo');
            if (this.get('customerProjectName')) title += ' ' + this.get('customerProjectName');
            return title;
        } else if (this.get('activityName'))
            return this.get('activityName');
        else
            return '';
    },

    totalInvoicedPrice: function() {
        var sum = 0;
        _(this.get('items')).each(function (item) {
            if (!item.articleInvoiced) return;
            if (sum != null && item.invoicedAmount != null && item.unitPrice != null)
                sum += item.invoicedAmount*item.unitPrice;
            else
                sum = null;
        });
        return sum;
    },

    // actions

    attest: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/attest',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    publish: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/publish',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },


});

var WorkReports = Backbone.Collection.extend({
	model: WorkReport,
    reportsFilterKey: 'reports.filterParams',
    defaultFilterParams: {
        path: 'all',
        includeAttested: true,
        includeInvoiced: true,
        includeClosed: true,
        from: null,
        to: null,
    },
	url: function() {
		if (this.customUrl)
			return this.customUrl;
		
		// Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.includeAttested) params.attested = '';
        if (this.filterParams.includeInvoiced) params.invoiced = '';
        if (this.filterParams.includeClosed) params.closed = '';
        if (this.filterParams.onlyInvoiced) params.onlyinvoiced = '';
        if (this.filterParams.limit) params.limit = this.filterParams.limit;

        if (this.searchQuery) {
            url = Core.contextRoot + '/workreport/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/workreport' + (this.filterParams.path ? '/'+this.filterParams.path : '');
            if (this.filterParams.path && this.filterParams.path == 'templates') {
                url = Core.contextRoot + '/workreport/templates';
                if (this.filterParams.includeDrafts) params.drafts = true;
                if (this.filterParams.onlyEditable) params.only_editable = true;
            } else {
                if (!this.filterParams.from) {
                    var fromDate = new Date();
                    fromDate.setHours(0,0,0,0);
                    fromDate.setDate(1);
                    this.filterParams.from = fromDate.getTime();
                    if (!this.filterParams.to) {
                        var toDate = fromDate;
                        toDate.addMonths(1);
                        this.filterParams.to = toDate.getTime();
                    }
                }
                params.from = this.filterParams.from;
                if (this.filterParams.to)
                    params.to = this.filterParams.to;
            }
            if (this.filterParams.params) _(params).extend(this.filterParams.params);
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;
        if (this.filterParams.offset) params.offset = this.filterParams.offset;
        if (this.filterParams.types) params.types = typeof this.filterParams.types == 'object' ? this.filterParams.types.join(',') : this.filterParams.types;
		var paramsStr = $.param(params);
		if (paramsStr.length > 0)
			url += '?' + paramsStr;
		return url;
	},
	
	initialize: function(models, options) {
        this.filterParams = _(this.defaultFilterParams).clone();
		if (options && options.url)
			this.customUrl = options.url;

        if (options && options.filterParams)
            _(this.filterParams).extend(options.filterParams);
		else if (this.reportsFilterKey in sessionStorage)
			this.filterParams = JSON.parse(sessionStorage[this.reportsFilterKey]);
		if (! 'path' in this.filterParams)
			this.filterParams.path = 'all';
	},

    saveFilter: function() {
        sessionStorage[this.reportsFilterKey] = JSON.stringify(this.filterParams);
    },

    forEachRelevantTemplate: function(callback, context) {
        var limitToPrimaryGroup = Core.client.me.get('shareAllWithPrimaryGroup') && this.filter(function (template) {
                return template.isValidForGroup(Core.client.me.get('primaryGroupId')) && Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'do_work');
            }).length > 0;
        return this.each(function (template) {
            var defaultTemplate = Core.client.primaryGroup() && Core.client.primaryGroup().getOrGetInParentGroup('defaultWorkReportTemplateId') == template.id;
            if (template.get('groupId') && !Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'do_work')) return;
            if (limitToPrimaryGroup && !template.isValidForGroup(Core.client.me.get('primaryGroupId'))) return;
            callback.apply(context, [template, defaultTemplate]);
        });
    },

    getTotalHours: function(includeAddOn) {
        var total = 0;
        _(this.models).each(function(report) {
            if (report.get('type') != 'ABSENCE') {
                total += report.getTotalHours(includeAddOn);
            }
        }, this);
        return total;
    },

    getTotalInvoicedHours: function(includeAddOn) {
        var total = 0;
        _(this.models).each(function(report) {
            if (report.isToBeInvoiced()) total += report.getTotalInvoicedHours(includeAddOn);
        }, this);
        return total;
    },

    sumAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var total = 0;
        _(this.models).each(function(report) {
            total += report.sumAmountsByUnit(unit, includeAddOn, requireWorkHours);
        }, this);
        return total;
    },

    sumAmountsByArticle: function(articleId) {
        var total = 0;
        _(this.models).each(function(report) {
            total += report.sumAmountsByArticle(articleId);
        }, this);
        return total;
    },

    sumInvoicedAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var total = 0;
        _(this.models).each(function(report) {
            if (report.isToBeInvoiced()) total += report.sumInvoicedAmountsByUnit(unit, includeAddOn, requireWorkHours);
        }, this);
        return total;
    },

    haveSpecificInvoicedAmounts: function(unit) {
        if (this.models.length > 0) {
            for (var i = 0; i < this.models.length; i++) {
                var report = this.models[i];
                if (report.hasSpecificInvoicedAmount(unit))
                    return true;
            }
            return false;
        }
    },

    haveOnlyInvoicedAmounts: function(unit) {
        if (this.models.length > 0) {
            for (var i = 0; i < this.models.length; i++) {
                var report = this.models[i];
                if (!report.hasOnlyInvoicedAmount(unit))
                    return false;
            }
            return true;
        }
    },

    haveAnyAmounts: function(unit) {
        for (var i = 0; i < this.models.length; i++) {
            var report = this.models[i];
            if (report.hasAnyAmount(unit))
                return true;
        }
        return false;
    },

    sumInvoicedPrice: function(unit, onlyInvoiced, onlyWorkHours) {
        var sum = 0;
        for (var i = 0; i < this.models.length; i++) {
            var report = this.models[i];
            var reportSum = report.sumInvoicedPrice(unit, onlyInvoiced, onlyWorkHours);
            if (reportSum == null) return null;
            sum += reportSum;
        }
        return sum;
    },

    sumAmountsToInvoice: function(unit) {
        var sum = 0;
        for (var i = 0; i < this.models.length; i++) {
            var report = this.models[i];
            if (report.isInvoiced()) continue;
            var reportSum = report.sumAmountsToInvoice(unit);
            if (reportSum == null) return null;
            sum += reportSum;
        }
        return sum;
    },

    isPriced: function(unit) {
        if (!this.models || this.models.length == 0)
            return false;
        for (var i = 0; i < this.models.length; i++) {
            var report = this.models[i];
            if (!report.isPriced(unit)) return false;
        }
        return true;
    },

    getTotalAbsenceHours: function(activityId) {
        var total = 0;
        _(this.models).each(function(model) {
            if (model.get('type') == 'ABSENCE' && (!activityId || model.get('activityId') == activityId))
                total += model.getTotalHours();
        });
        return total;
    },
});

WorkReports.filterCollection = function(filter, collection) {
    var filter = _(filter).clone();
    if (!filter.list) filter.list = 'all';
    var label;
    var assetTypeId;
    if (filter.list == 'mine') {
        filter.where = { userId: Core.client.me.id };
    } else if (filter.list.indexOf('user-') == 0) {
        filter.where = { userId: parseInt(filter.list.replace('user-', '')) };
    } else if (filter.list.indexOf('label-') == 0) {
        label = filter.list.replace('label-', '');
        delete filter.where;
    } else if (filter.list.indexOf('vehicle-') == 0) {
        filter.where = { vehicleId: parseInt(filter.list.replace('vehicle-', '')) };
    } else if (filter.list.indexOf('group-') == 0) {
        filter.where = { groupId: parseInt(filter.list.replace('group-','')) };
    } else if (filter.list.indexOf('customer-') == 0) {
        filter.where = { customerId: parseInt(filter.list.replace('customer-', '')) };
    } else if (filter.list.indexOf('asset-') == 0) {
        filter.where = {assetId: parseInt(filter.list.replace('asset-', ''))};
    } else if (filter.list.indexOf('assettype-') == 0) {
        assetTypeId = parseInt(filter.list.replace('assettype-', ''));
        delete filter.where;
    } else if (filter.list != 'all') {
        console.error('Unknown filter selection ' + filter.list);
    }

    var filteredCollection = new WorkReports(filter.where ? collection.where(filter.where) : collection.models);
    if (assetTypeId) {
        console.log('filter reports on ' + assetTypeId);
        filteredCollection.reset(filteredCollection.filter(function (job) {
            var asset = Core.client.assets.get(job.get('assetId'));
            return asset && asset.get('assetTypeId') == assetTypeId;
        }, this), {silent: true})
    }
    if (label) {
        filteredCollection.reset(filteredCollection.filter(function (model) {
            return model.get('labels') && model.get('labels').indexOf(label) >= 0;
        }, this), { silent: true })
    }
    console.log('filteredActivities',collection.filterParams.activities);
    if (Core.client.activities != null && Core.client.activities.length > 0 && collection.filterParams && collection.filterParams.activities)
        filteredCollection.reset(filteredCollection.filter(function (report) {
            if (collection.filterParams.activities.indexOf(report.get('activityId')) < 0) return false;
            return true;
        }), {silent: true});

    filteredCollection.reset(filteredCollection.filter(function (model) { return !model.get('deleted'); }), { silent: true });
    return filteredCollection;
};
