"use strict";

var Item = Backbone.Model.extend({

});

var Items = Backbone.Collection.extend({
    model: Item,

    initialize: function(models, options) {
        if (options) {
            this.path = Core.contextRoot + options.path;
            this.filterParams = options.path;
        }
    },

    url: function() {
        var url = this.path;
        var params = {};
        if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
            var fromDate = new Date();
            fromDate.setHours(0,0,0,0);
            fromDate.setDate(1);
            this.filterParams.from = fromDate.getTime();
        }
        params.from = this.filterParams.from;
        if (this.filterParams.to) params.to = this.filterParams.to;
        if (this.filterParams.states) params.states = this.filterParams.states;
        if (this.filterParams.onlyInvoiced) params.onlyinvoiced = '';
        if (this.filterParams.includeInvoiced) params.invoiced = '';
        if (this.filterParams.includeClosed) params.closed = '';
        if (this.filterParams.params) _(params).extend(this.filterParams.params);
        var paramsStr = $.param(params);
        if (paramsStr.length > 0) url += '?' + paramsStr;
        return url;
    }
});

var ItemSummary = Backbone.Collection.extend({

    entity: 'Item',

    url: function() {
        if (this.customUrl)
            return this.customUrl;

        // Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.onlyInvoiced) params.invoiced = '';

        url = Core.contextRoot + '/article' + (this.filterParams.path ? '/' + this.filterParams.path : '') + '/summary';
        if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
            var fromDate = new Date();
            fromDate.setHours(0,0,0,0);
            fromDate.setDate(1);
            this.filterParams.from = fromDate.getTime();
        }
        params.from = this.filterParams.from;
        if (this.filterParams.to)
            params.to = this.filterParams.to;
        var paramsStr = $.param(params);
        if (paramsStr.length > 0)
            url += '?' + paramsStr;
        return url;
    },

    initialize: function(models, options) {
        this.filterParams = options && options.filterParams || {};
    },

    combinedSummaryBy: function(attribute) {
        var combinedModels = [];
        var combinedModelByKey = {}
        this.each(function(model) {
            var key = model.get(attribute);
            var combinedModel = combinedModelByKey[key];
            if (!combinedModel) {
                combinedModel = model.clone();
                combinedModelByKey[key] = combinedModel;
                combinedModels.push(combinedModel);
            } else {
                _(Object.keys(combinedModel.attributes)).each(function (key) {
                    if (key == 'count' || key == 'amount' || key == 'invoicedAmount' || key == 'invoicedPrice') return;
                    if (combinedModel.get(key) != model.get(key))
                        combinedModel.set(key, null);
                }, this);
                var count = model.get('count');
                if (count) combinedModel.set('count', combinedModel.get('count') ? combinedModel.get('count') + count : count);
                var amount = model.get('amount');
                if (amount) combinedModel.set('amount', combinedModel.get('amount') ? combinedModel.get('amount') + amount : amount);
                var invoicedAmount = model.get('invoicedAmount');
                if (invoicedAmount) combinedModel.set('invoicedAmount', combinedModel.get('invoicedAmount') ? combinedModel.get('invoicedAmount') + invoicedAmount : invoicedAmount);
                var invoicedPrice = model.get('invoicedPrice');
                if (invoicedPrice == null) combinedModel.set('invoicedPrice', null);
                else if (invoicedPrice && combinedModel.get('invoicedPrice') !== null) combinedModel.set('invoicedPrice', combinedModel.get('invoicedPrice') ? combinedModel.get('invoicedPrice') + invoicedPrice : invoicedPrice);
            }
        }, this);
        return new ItemSummary(combinedModels);
    },

    subSummaryWhere: function(selection) {
        return new ItemSummary(this.where(selection));
    },

    sum: function(attribute) {
        var sum = 0;
        this.each(function (entry) { sum += entry.get(attribute) ? entry.get(attribute) : 0; });
        return sum;
    },

    totalAmount: function() { return this.sum('amount'); },
    totalInvoicedAmount: function() { return this.sum('invoicedAmount'); },
    totalInvoicedPrice: function() { return this.pluck('invoicedPrice').indexOf(null) < 0 ? this.sum('invoicedPrice') : undefined; },
    totalCount: function() { return this.sum('count'); },
    unit: function() {
        var units = _(this.pluck('unit')).uniq();
        if (units.length == 1) return units[0];
    },
    currency: function() {
        var currencies = [];
        this.each(function (model) {
            if (model.get('currency') == null && model.get('groupId')) {
                var group = Core.client.groups.get(model.get('groupId'));
                currencies.push(group && group.get('defaultCurrency') || null);
            } else
                currencies.push(model.get('currency'));
        }, this);
        currencies = _(currencies).uniq();
        if (currencies.length == 1) return currencies[0];
    },
});

